import React, { useContext } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { IEventInstanceSelect } from 'vigil-datamodel';
import { TTuuid } from 'tt-uuid';
import { ContextOrganization } from '../../providers/provider_organization';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { ROUTES } from '../../router/routes';
import { useTableData } from '../../hooks/hook_table_data';

interface ScreenHomeEventProps { }

export const ScreenHomeEventInstances: React.FC<ScreenHomeEventProps> = (props) => {
  const navigate = useNavigate()
  const params = useParams();
  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const uuidEvent = params['uuid'] || '';

  const { data: eventInstances, loading, error, paging, filters, sorting, handleFiltersChange, handleSortingChange, handlePagingChange } = useTableData<IEventInstanceSelect>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryEventInstances({
        uuidOrganization: organization.data.uuid,
        filters: [...filters, { id: 'uuidEvent', query: 'equals', value: uuidEvent }],
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data, uuidEvent],
  });

  function navigateEventInstance(selectedEventInstance: IEventInstanceSelect) {
    navigate(ROUTES.ROUTE_HOME_EVENT_INSTANCE.replace(':uuid', selectedEventInstance.uuid) + `?${createSearchParams({ source_event: uuidEvent })}`);
  }

  return (
    <div className='flex w-full h-full flex-col py-2'>
      <Table<IEventInstanceSelect>
        className='h-full flex flex-col drop-shadow'
        name='Event Instances'
        data={eventInstances}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'uuid',
            header: 'Unique ID',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateEventInstance(item)}>
                  {item.uuid}
                </div>
              );
            },
          },
          {
            id: 'event_name',
            header: 'Event Name',
            sortable: true,
            value(item) {
              return <div>{item.eventSnapshot.name}</div>;
            },
          },
          {
            id: 'date_created',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'last_updated',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}

        filtersOptions={[
          new TextFilter('uuid', 'Unique ID', 'contains'),
          new DateFilter('date_created', 'Date Created', 'between'),
          new DateFilter('last_updated', 'Last Updated', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div>
  )
};
