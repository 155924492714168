import { lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { RouterBase } from "./router_base";
import { ROUTES } from "./routes";
import { FullPageError } from "../components/full_page_error";
import { FullPageSuspend } from "../components/full_page_suspend";
import { FullPageLoader } from "../components/full_page_loader";

// Lazy load pages for performance.
const Screen404 = lazy(() => import('../screens/404'));

const router = createBrowserRouter([
  ...RouterBase,
  {
    path: "*",
    errorElement: <FullPageError />,
    element: <FullPageSuspend><Screen404 /></FullPageSuspend>
  },
], {
  basename: ROUTES.ROUTE_APP_BASE
});

export function RouterView() {
  return <RouterProvider router={router} fallbackElement={<FullPageLoader />} />
}
