import { RouteObject } from "react-router-dom";
import { RouterHome } from "./router_home";
import { RouterProfile } from "./router_profile";
import { RouterAdmin } from "./router_admin";
import { ROUTES } from "../routes";
import { FullPageSuspend } from "../../components/full_page_suspend";
import { lazy } from "react";
import { ScreenHomeToolbox } from "../../screens/dashboard/home_toolbox";

const ScreenSignInRequest = lazy(() => import('../../screens/account/sign_in_request'));
const ScreenSignInConfirm = lazy(() => import('../../screens/account/sign_in_confirm'));
const ScreenUserCreate = lazy(() => import('../../screens/account/user_create'));
const ScreenUserCreateEmailRequest = lazy(() => import('../../screens/account/user_create_email_request'));
const ScreenUserCreateEmailConfirm = lazy(() => import('../../screens/account/user_create_email_confirm'));
const ScreenUserCreateMobileRequest = lazy(() => import('../../screens/account/user_create_mobile_request'));
const ScreenUserCreateMobileConfirm = lazy(() => import('../../screens/account/user_create_mobile_confirm'));

export const RouterBaseAuth: RouteObject[] = [
  ...RouterHome,
  ...RouterProfile,
  ...RouterAdmin,
  {
    path: ROUTES.ROUTE_SIGN_IN_REQUEST,
    element: <FullPageSuspend><ScreenSignInRequest /></FullPageSuspend>,
  },
  {
    path: ROUTES.ROUTE_SIGN_IN_CONFIRM,
    element: <FullPageSuspend><ScreenSignInConfirm /></FullPageSuspend>,
  },
  {
    path: ROUTES.ROUTE_USER_CREATE,
    element: <FullPageSuspend><ScreenUserCreate /></FullPageSuspend>,
  },
  {
    path: ROUTES.ROUTE_USER_CREATE_EMAIL_REQUEST,
    element: <FullPageSuspend><ScreenUserCreateEmailRequest /></FullPageSuspend>,
  },
  {
    path: ROUTES.ROUTE_USER_CREATE_EMAIL_CONFIRM,
    element: <FullPageSuspend><ScreenUserCreateEmailConfirm /></FullPageSuspend>,
  },
  {
    path: ROUTES.ROUTE_USER_CREATE_MOBILE_REQUEST,
    element: <FullPageSuspend><ScreenUserCreateMobileRequest /></FullPageSuspend>,
  },
  {
    path: ROUTES.ROUTE_USER_CREATE_MOBILE_CONFIRM,
    element: <FullPageSuspend><ScreenUserCreateMobileConfirm /></FullPageSuspend>,
  },
  {
    path: ROUTES.ROUTE_TOOLBOX,
    element: <FullPageSuspend><ScreenHomeToolbox /></FullPageSuspend>,
  },
];