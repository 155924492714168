import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IBeaconSelect, ISiteSelect, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ROUTES } from '../../router/routes';
import { InputButton } from '../../components/input_button';
import { IconPencilSquareSolid, IconTrashSolid, IconPlusSolid, IconLinkSolid } from '../../components/icons';
import { useModal } from '../../hooks/hook_modal';
import { ModalBeaconCreate } from './func_beacon/modal_beacon_create';
import { ModalSiteUpdate } from './func_site/modal_site_update';
import { ModalSiteBeaconsCreate } from './func_beacon_site/modal_site_beacons_create';
import { ModalSiteBeaconsDelete } from './func_beacon_site/modal_site_beacons_delete';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import SiteView from '../shared/site_view';
import { ModalDeleteMany } from '../../components/modal_delete_many';
import { ContextOrganization } from '../../providers/provider_organization';

export const ScreenHomeSite: React.FC = () => {
  const navigate = useNavigate();
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);

  const [stateError, setError] = useState<string>('');
  const [stateSiteLoading, setSiteLoading] = useState(true);
  const [stateSite, setSite] = useState<ISiteSelect>();
  const [stateBeaconsLoading, setBeaconsLoading] = useState(true);
  const [stateBeacons, setBeacons] = useState<IBeaconSelect[]>();
  const [stateSelectedBeacon, setSelectedBeacon] = useState<IBeaconSelect>();

  const { isOpen: isOpenModalUpdateSite, toggle: toggleModalUpdateSite } = useModal();
  const { isOpen: isOpenModalDeleteSite, toggle: toggleModalDeleteSite } = useModal();
  const { isOpen: isOpenModalCreateBeacon, toggle: toggleModalCreateBeacon } = useModal();
  const { isOpen: isOpenModalDeleteSiteBeacons, toggle: toggleModalDeleteSiteBeacons } = useModal();
  const { isOpen: isOpenModalCreateSiteBeacons, toggle: toggleModalCreateSiteBeacons } = useModal();

  const params = useParams();
  const uuidSite = params['uuid'] || '';

  useEffect(() => {
    fetchSite(uuidSite);
    fetchBeacons(uuidSite);
  }, [uuidSite]);

  async function fetchSite(uuid: string) {
    try {
      if (!organization.data) throw new Error('No organization data');
      setSiteLoading(true);
      const site = await vigil.functions.findOneOnOrganization({ type: 'sites', uuidOrganization: organization.data.uuid, uuid: uuid });
      setSite(site);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSiteLoading(false);
    }
  }

  async function fetchBeacons(uuidSite: string) {
    try {
      if (!organization.data) throw new Error('No organization data');
      setBeaconsLoading(true);
      const beacons = await vigil.functions.findManyLinkedToSite({ type: 'beacons', uuidOrganization: organization.data.uuid, uuidSite });
      setBeacons(beacons);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setBeaconsLoading(false);
    }
  }

  const handleBeaconSelect = (beacon: IBeaconSelect) => {
    setSelectedBeacon(beacon);
  };

  const additionalActions = (
    <>
      <div className='mx-4'>
        <InputButton text='Edit Site' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.U, Permissions.RSite)} onClick={toggleModalUpdateSite} />
      </div>
      <InputButton text='Delete Site' before={<IconTrashSolid className='h-5 mr-2' />} type='btn-error' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.RSite)} onClick={toggleModalDeleteSite} />
    </>
  );

  const breadcrumbs = [
    { id: 'sites', onClick: () => navigate(ROUTES.ROUTE_HOME_SITES), text: 'Sites' },
    { id: uuidSite, onClick: () => { }, text: stateSite?.name || '' }
  ];

  return (
    <>
      <ModalSiteUpdate isOpen={isOpenModalUpdateSite} toggle={toggleModalUpdateSite} onSubmit={async () => await fetchSite(uuidSite)} uuidSite={uuidSite} />
      <ModalDeleteMany
        isOpen={isOpenModalDeleteSite}
        toggle={toggleModalDeleteSite}
        type='sites'
        data={[{ uuid: stateSite?.uuid || '', label: stateSite?.name || '' }]}
        onSubmit={async () => navigate(ROUTES.ROUTE_HOME_SITES)}
        extraComponents={<li>Remove the site-beacon links, but not the beacons.</li>}
      />
      <ModalBeaconCreate isOpen={isOpenModalCreateBeacon} toggle={toggleModalCreateBeacon} uuidSite={uuidSite} onSubmit={async () => await fetchBeacons(uuidSite)} />
      <ModalSiteBeaconsDelete isOpen={isOpenModalDeleteSiteBeacons} toggle={toggleModalDeleteSiteBeacons} uuidSite={uuidSite} uuidBeacons={stateSelectedBeacon ? [stateSelectedBeacon.uuid] : []} onSubmit={async () => await fetchBeacons(uuidSite)} />
      <ModalSiteBeaconsCreate isOpen={isOpenModalCreateSiteBeacons} toggle={toggleModalCreateSiteBeacons} uuidSite={uuidSite} onSubmit={async () => await fetchBeacons(uuidSite)} />

      <SiteView
        site={stateSite}
        beacons={stateBeacons}
        isLoading={stateSiteLoading || stateBeaconsLoading}
        error={stateError}
        onBeaconSelect={handleBeaconSelect}
        breadcrumbs={breadcrumbs}
        additionalActions={additionalActions}
        isPublic={false}
      >
        <div className='p-2 flex flex-row'>
          <InputButton text='Create Beacon' before={<IconPlusSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RBeacon)} onClick={toggleModalCreateBeacon} />
          <div className='mx-2'></div>
          <InputButton text='Link Beacon' before={<IconLinkSolid className='h-5 mr-2' />} type='btn-secondary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RBeaconSiteLink)} onClick={toggleModalCreateSiteBeacons} />
        </div>
      </SiteView>
    </>
  );
};