import { TTFunction } from "tt-coms";
import { IActionInsert, IActionSelect, IBeaconInsert, IBeaconSelect, IDeviceInsert, IDeviceSelect, IEventInsert, IEventSelect, IOrganizationRoleInsert, IOrganizationRoleSelect, IReportInsert, IReportSelect, ISiteInsert, ISiteSelect, IUserInsert, IUserSelect } from "./beans_type";

type UpdateOneOnOrganization = 'users' | 'sites' | 'beacons' | 'devices' | 'events' | 'organizationRoles' | 'actions' | 'reports';

type InferInputUpdateOneOnOrganization = {
  'users': Partial<IUserInsert>,
  'sites': Partial<ISiteInsert>,
  'beacons': Partial<IBeaconInsert>,
  'devices': Partial<IDeviceInsert>,
  'events': Partial<IEventInsert>,
  'actions': Partial<IActionInsert>,
  'reports': Partial<IReportInsert>,
  'organizationRoles': Partial<IOrganizationRoleInsert>
}

type InferResultUpdateOneOnOrganization = {
  'users': IUserSelect,
  'sites': ISiteSelect,
  'beacons': IBeaconSelect,
  'devices': IDeviceSelect,
  'events': IEventSelect,
  'actions': IActionSelect,
  'reports': IReportSelect,
  'organizationRoles': IOrganizationRoleSelect
}

export function createUpdateOneOnOrganization() {
  const baseFunction = TTFunction<{ type: UpdateOneOnOrganization; uuidOrganization: string; uuid: string, data: InferInputUpdateOneOnOrganization[UpdateOneOnOrganization] }, InferResultUpdateOneOnOrganization[UpdateOneOnOrganization] | undefined>();

  return async function <T extends UpdateOneOnOrganization>(input: { type: T, uuidOrganization: string, uuid: string, data: InferInputUpdateOneOnOrganization[T] }): Promise<InferResultUpdateOneOnOrganization[T] | undefined> {
    return baseFunction(input) as Promise<InferResultUpdateOneOnOrganization[T] | undefined>;
  }
}
