import React, { useState } from 'react';
import { IDirectoryDevice } from 'vigil-datamodel';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { TTuuid } from 'tt-uuid';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, SelectedAction, Sorting, UnselectedAction } from '../../components/table/table_types';

interface DevicesTableProps {
  devices: IDirectoryDevice[];
  loading: boolean;
  error: string;
  onDeviceClick: (device: IDirectoryDevice) => void;
  onFiltersChange: (filters: QueryFilter<IDirectoryDevice>[]) => void;
  onSortingChange: (sorting: Sorting) => void;
  tablePagingSelected: Paging;
  onPagingChange: (paging: Paging) => void;
  selectedActions?: SelectedAction<IDirectoryDevice>[];
  unSelectedActions?: UnselectedAction[];
  hoverActions?: SelectedAction<IDirectoryDevice>[];
  selectable?: boolean;
}

export const DevicesTable: React.FC<DevicesTableProps> = ({
  devices,
  loading,
  error,
  onDeviceClick,
  onFiltersChange,
  onSortingChange,
  tablePagingSelected,
  onPagingChange,
  selectedActions,
  unSelectedActions,
  hoverActions,
  selectable = false,
}) => {
  const [stateTableFiltersSelected, setTableFiltersSelected] = useState<QueryFilter<IDirectoryDevice>[]>([]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState<Sorting>({ id: 'uuid', direction: 'descending' });
  const [stateTablePagingSelected, setTablePagingSelected] = useState<Paging>({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 });

  const handleFiltersChange = (filters: QueryFilter<IDirectoryDevice>[]) => {
    setTableFiltersSelected(filters);
    onFiltersChange(filters);
  };

  const handleSortingChange = (sorting: Sorting) => {
    setTableSortingSelected(sorting);
    onSortingChange(sorting);
  };

  const handlePagingChange = (paging: Paging) => {
    setTablePagingSelected(paging);
    onPagingChange(paging);
  };

  return (
    <Table<IDirectoryDevice>
      className='h-full flex flex-col drop-shadow'
      name='Devices'
      data={devices}
      selectable={selectable}
      loading={loading}
      error={error}
      columns={[
        {
          id: 'uuid',
          header: 'Unique ID',
          sortable: true,
          value(item) {
            return (
              <div className='hover:underline cursor-pointer text-blue-500' onClick={() => onDeviceClick(item)}>
                {item.uuid}
              </div>
            );
          },
        },
        {
          id: 'serial',
          header: 'Serial Number',
          sortable: true,
          value(item) {
            return <div>{item.uuid}</div>;
          },
        },
        {
          id: 'uuid',
          header: 'Date Created',
          sortable: true,
          value(item) {
            const decodedUuid = TTuuid.decodeCuuid(item.uuid);
            return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
          },
        },
        {
          id: 'changeStamp',
          header: 'Last Updated',
          sortable: true,
          value(item) {
            const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
            return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
          },
        },
      ]}
      filtersOptions={[
        new TextFilter('uuid', 'Unique ID', 'contains'),
        new TextFilter('serialNumber', 'Serial Number', 'contains'),
        new DateFilter('uuid', 'Date Created', 'between'),
        new DateFilter('changeStamp', 'Last Updated', 'between'),
      ]}
      filtersSelected={stateTableFiltersSelected}
      onFiltersChange={handleFiltersChange}
      sortingOptions={null}
      sortingSelected={stateTableSortingSelected}
      onSortingChange={handleSortingChange}
      pagingOptions={null}
      pagingSelected={tablePagingSelected}
      onPagingChange={handlePagingChange}
      unSelectedActions={unSelectedActions}
      selectedActions={selectedActions}
      hoverActions={hoverActions}
    />
  );
};