import { useContext, useEffect, useState } from "react";
import { ActionStepEmail, ActionStepEmailButton, ActionStepType } from "vigil-datamodel/src/bean_action";
import { InputText } from "../../../components/input_text";
import { IconXCircleSolid } from "../../../components/icons";
import { VALIDATORS, validate } from "../../../validation";
import { InputTextArea } from "../../../components/input_text_area";
import { IReportSelect, extractReportUuid, getReportPublicURI } from "vigil-datamodel";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { StatusAlert } from "../../../components/status_alert";
import { CheckboxItems } from "../../../components/checkbox_group";
import { InputCheckboxGroup } from "../../../components/input_checkbox_group";

interface ActionStepEmailProps {
  step?: ActionStepEmail;
  setStep: (step: ActionStepEmail) => void;
  setValidateForm: (validateForm: string[]) => void;
}

export const ActionStepEmailComponent: React.FC<ActionStepEmailProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');

  const [stateToEmails, setToEmails] = useState<string[]>(props.step?.toEmails || []);
  const [stateInputEmail, setInputEmail] = useState<string>('');

  const [stateSubject, setSubject] = useState<string>(props.step?.subject || '');
  const [stateBody, setBody] = useState<string>(props.step?.body || '');

  const [reportsLoading, setReportsLoading] = useState<boolean>(false);
  const [reportsError, setReportsError] = useState<string>('');
  const [stateReports, setReports] = useState<IReportSelect[]>([]);
  const [stateReportCheckboxGroup, setReportCheckboxGroup] = useState<CheckboxItems>({});

  useEffect(() => {
    const checkedReports = stateReports.filter(report => stateReportCheckboxGroup[report.uuid].checked);
    const buttons = checkedReports.map(report => {
      const url = getReportPublicURI(getBaseUrl(), report.uuid);
      return { text: `View ${report.name} report`, url } as ActionStepEmailButton;
    });
    props.setStep({
      type: ActionStepType.Email,
      enabled: true,
      toEmails: stateToEmails,
      subject: stateSubject,
      body: stateBody,
      buttons,
      attachments: [],
    });
    props.setValidateForm(validateForm());
  }, [stateToEmails, stateSubject, stateBody, stateReportCheckboxGroup]);

  useEffect(() => { fetchReports(); }, []);

  async function fetchReports() {
    try {
      if (!organization.data) throw new Error('Organization not found');
      setReportsLoading(true);
      const reports = await vigil.functions.findManyLinkedToOrganization({
        type: 'reports',
        uuidOrganization: organization.data.uuid,
        filters: []
      })
      setReports(reports);
      // TODO: Not ideal, but best for now
      const reportUuids = props.step?.buttons.map(button => extractReportUuid(button.url)) || [];
      setReportCheckboxGroup((prevState: CheckboxItems) => {
        const updatedItems: CheckboxItems = {} as CheckboxItems;
        for (const report of reports) {
          updatedItems[report.uuid] = { label: report.name, checked: reportUuids.includes(report.uuid) }
        }
        return { ...prevState, ...updatedItems };
      });
    } catch (error: any) {
      setReportsError(error.message);
    } finally {
      setReportsLoading(false);
    }
  }

  /* Functions */
  function getBaseUrl() {
    // TODO: This should be defined in one place
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}${port ? `:${port}` : ''}/`;
  };

  function addEmail(email: string) {
    setToEmails([...stateToEmails, email]);
    setInputEmail('');
  }

  function clearEmail(email: string) {
    setToEmails(stateToEmails.filter((stateEmail) => stateEmail != email));
  }

  /* Validations */
  function validateInputEmail() {
    if (stateInputEmail.length == 0) return [];
    return validate(stateInputEmail, [VALIDATORS.email()]);
  }

  function validateToEmails() {
    if (stateToEmails.length == 0) return ['Need to add atleast one email'];
    return [];
  }

  function validateSubject() {
    if (stateSubject.length == 0) return ['Need to add a subject'];
    return [];
  }

  function validateBody() {
    if (stateBody.length == 0) return ['Need to add a body'];
    return [];
  }

  function validateForm() {
    const validateForm: string[] = [];
    return [
      ...validateForm,
      ...validateInputEmail(),
      ...validateToEmails(),
      ...validateSubject(),
      ...validateBody(),
    ]
  }

  if (stateError) return <StatusAlert message={stateError} type={"alert-error"} />;

  return (
    <div className="flex flex-wrap">
      <div className="w-full flex flex-wrap">
        {stateToEmails.map((email, index) => {
          return (
            <div key={email} className='flex items-center justify-center bg-secondary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              <div>{email}</div>
              <button onClick={() => { clearEmail(email) }}>
                <IconXCircleSolid className='ml-2' style={{ height: '18px' }} />
              </button>
            </div>
          )
        })}
      </div>
      <div className="flex mr-2">
        <InputText labelText={"Add New Email"} value={stateInputEmail} onChange={(event) => setInputEmail(event.target.value)} errors={validateInputEmail()}></InputText>
        <div className="flex flex-col">
          <div className="flex flex-grow"></div>
          <button className="btn btn-primary ml-2 mb-2" disabled={stateInputEmail.length == 0 || validateInputEmail().length > 0} onClick={() => { addEmail(stateInputEmail) }}>Add</button>
          {validateInputEmail().length > 0 && <div className="mb-5" />}
        </div>
      </div>
      <div className="w-full">
        <InputText labelText={"Subject"} value={stateSubject} onChange={(event) => setSubject(event.target.value)} errors={validateSubject()}></InputText>
        <InputTextArea labelText="Body" value={stateBody} onChange={(event) => setBody(event.target.value)} errors={validateBody()}></InputTextArea>
        <InputCheckboxGroup
          labelText="Reports to be added to the email"
          errors={reportsError ? [reportsError] : []}
          loading={reportsLoading}
          checkboxGroup={{
            label: 'Select All Reports',
            items: stateReportCheckboxGroup,
            onChange: (updatedItems) => setReportCheckboxGroup(updatedItems)
          }}
        />
      </div>
    </div>
  )
}