import React, { useContext, useState } from 'react';
import { InputButton } from '../../components/input_button';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextUser } from '../../providers/provider_user';
import { ContextOrganization } from '../../providers/provider_organization';
import { NotificationType } from 'vigil-datamodel';

interface ScreenHomeToolboxProps { }

export const ScreenHomeToolbox: React.FC<ScreenHomeToolboxProps> = (props) => {
  // const vigil = useContext(ContextVigilClient);
  // const user = useContext(ContextUser);
  // const organization = useContext(ContextOrganization);

  // /* Panic */
  // const [stateLoadingPanic, setLoadingPanic] = useState(false);
  // const [stateErrorPanic, setErrorPanic] = useState("");

  // async function panic() {
  //   try {
  //     const notification = await vigil.collectionNotifications.createOne({
  //       type: NotificationType.Panic,
  //       creator: { type: 'server' },
  //       uuidOrganization: organization.data!.uuid,
  //       data: {
  //         deviceUuid: "toolbox",
  //         userFirstName: user.data!.firstName,
  //         userLastName: user.data!.lastName,
  //         userUuid: user.data!.uuid,
  //         acc: 0,
  //         lat: 0,
  //         lng: 0,
  //         alt: 0,
  //       }
  //     });
  //   }
  //   catch (error) {

  //   }
  //   finally {

  //   }
  // }

  // return (
  //   <div className='p-4 h-full w-full flex flex-col'>
  //     <div> toolbox </div>
  //     <InputButton type='btn-primary' text='Create Panic' loading={stateLoadingPanic} disabled={false} onClick={() => panic()} />
  //   </div>
  // );
  return <></>;
};
