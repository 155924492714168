import { useEffect, useRef, useState } from 'react';
import { SelectedAction, UnselectedAction, ColumnInfo, Sorting, Paging } from './table_types';
import { StatusAlert } from '../status_alert';
import { TableHeader } from './table_header';
import { TableBody } from './table_body';
import { TableFooter } from './table_footer';
import { BaseFilter, QueryFilter } from './table_filters/table_filter_types';
import { TableFilterBar } from './table_filter_bar';
import { TableActionBar } from './table_action_bar';

export interface TableProps<T> {
  className?: string;

  name: string;
  data: T[],
  columns: ColumnInfo<T>[]

  loading?: boolean;
  error?: string;

  filtersOptions: BaseFilter[];
  filtersSelected: QueryFilter<T>[];
  onFiltersChange: (filters: QueryFilter<T>[]) => void;

  sortingOptions: null; // TODO: Could add future configs here
  sortingSelected: Sorting;
  onSortingChange: (sorting: Sorting) => void;

  pagingOptions: null;
  pagingSelected: Paging;
  onPagingChange: (paging: Paging) => void;

  selectable?: boolean;
  singleSelectActions?: SelectedAction<T>[];
  selectedActions?: SelectedAction<T>[];
  unSelectedActions?: UnselectedAction[];
  hoverActions?: SelectedAction<T>[];
}

/**
 * Table Component
 * 
 * A flexible and feature-rich table component for displaying and interacting with tabular data.
 * 
 * Features:
 * - Customizable columns with sorting capabilities
 * - Selectable rows with single and multi-select actions
 * - Filtering system with various filter types (text, date, etc.)
 * - Pagination with customizable items per page
 * - Responsive design with mobile-friendly layout
 * - Loading state and error handling
 * - Hover actions for individual rows
 * 
 * @template T - The type of data items displayed in the table
 * 
 * @param {Object} props - The component props
 * @param {string} props.className - Additional CSS classes for the table container
 * @param {string} props.name - The name of the table (used in UI elements)
 * @param {T[]} props.data - The array of data items to display
 * @param {ColumnInfo<T>[]} props.columns - The configuration for table columns
 * @param {boolean} [props.loading] - Indicates if the table is in a loading state
 * @param {string} [props.error] - Error message to display if there's an error
 * @param {BaseFilter[]} props.filtersOptions - Available filter options
 * @param {QueryFilter<T>[]} props.filtersSelected - Currently applied filters
 * @param {(filters: QueryFilter<T>[]) => void} props.onFiltersChange - Callback for filter changes
 * @param {null} props.sortingOptions - (Reserved for future use)
 * @param {Sorting} props.sortingSelected - Current sorting configuration
 * @param {(sorting: Sorting) => void} props.onSortingChange - Callback for sorting changes
 * @param {null} props.pagingOptions - (Reserved for future use)
 * @param {Paging} props.pagingSelected - Current pagination configuration
 * @param {(paging: Paging) => void} props.onPagingChange - Callback for pagination changes
 * @param {boolean} [props.selectable] - Enables row selection
 * @param {SelectedAction<T>[]} [props.singleSelectActions] - Actions for single row selection
 * @param {SelectedAction<T>[]} [props.selectedActions] - Actions for multi-row selection
 * @param {UnselectedAction[]} [props.unSelectedActions] - Actions when no rows are selected
 * @param {SelectedAction<T>[]} [props.hoverActions] - Actions displayed on row hover
 * 
 * @returns {JSX.Element} The rendered Table component
 */
export const Table = <T,>(props: TableProps<T>) => {
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});

  useEffect(() => {
    // Reset selected rows when data changes
    setSelectedRows({});
  }, [props.data]);

  function getSelectedItems(): T[] {
    return props.data.filter((_, index) => getRowSelected(index));
  }

  const setRowsSelected = (value: boolean) => {
    const newSelectedRows: Record<number, boolean> = {};
    props.data.forEach((_, index) => {
      newSelectedRows[index] = value;
    });
    setSelectedRows(newSelectedRows);
  }

  const getRowsSelected = () => {
    if (props.data.length === 0) {
      return false;
    }
    return props.data.every((_, index) => getRowSelected(index));
  }

  const setRowSelected = (index: number, value: boolean) => {
    setSelectedRows(prevState => ({
      ...prevState,
      [index]: value
    }));
  };

  const getRowSelected = (index: number) => {
    return selectedRows[index] || false;
  };

  const colorClassMap = {
    primary: 'text-primary hover:bg-primary',
    secondary: 'text-secondary hover:bg-secondary',
    warning: 'text-warning hover:bg-warning',
    error: 'text-error hover:bg-error',
  };

  return (
    <div className={props.className ?? 'h-full flex flex-col'}>
      {props.error && <StatusAlert message={props.error} type='alert-error' />}
      {!props.error &&
        <div className='border border-base-200 rounded-2xl h-full flex flex-col'>
          <TableActionBar props={{ ...props, setRowsSelected, getSelectedItems, colorClassMap }} />
          {props.filtersOptions.length > 0 &&
            <TableFilterBar
              onFiltersChange={props.onFiltersChange}
              filtersSelected={props.filtersSelected}
              filtersOptions={props.filtersOptions}
            />
          }

          {props.loading && <progress className="progress progress-primary w-full h-1"></progress>}

          <div className="relative w-full h-full">
            <div className='absolute w-full h-full overflow-y-auto'>
              {props.data.length > 0 ? (
                <table className="table table-sm w-full">
                  <TableHeader
                    columns={props.columns}
                    rowsSelectable={props.selectable || false}
                    sortingSelected={props.sortingSelected}
                    rowsSelected={getRowsSelected()}
                    onRowsSelectedChange={setRowsSelected}
                    onSortingChange={props.onSortingChange}
                  />
                  <TableBody
                    getRowSelected={getRowSelected}
                    setRowSelected={setRowSelected}
                    selectable={props.selectable || false}
                    columns={props.columns}
                    data={props.data}
                    hoverActions={props.hoverActions}
                    colorClassMap={colorClassMap}
                  />
                </table>
              ) : (
                <div className='w-full h-56 flex flex-col justify-center items-center'>
                  <div>No items were found</div>
                </div>
              )}
            </div>
            {props.loading && <div className="absolute inset-0 bg-base-100 bg-opacity-80" />}
          </div>

          <div className='flex-grow' />

          <TableFooter
            pagingOptions={props.pagingOptions}
            pagingSelected={props.pagingSelected}
            onPagingChange={props.onPagingChange}
          />
        </div>
      }
    </div>
  );
}
