import { useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputButton } from "../../input_button";
import { InputText } from "../../input_text";
import { BaseFilter, TextQueries, QueryFilter, filterQueries } from "./table_filter_types";

export class TextFilter<T> implements BaseFilter {
  id: string;
  name: string;
  query: TextQueries;

  constructor(id: keyof T, name: string, query: TextQueries) {
    this.id = id as string;
    this.name = name;
    this.query = query;
  }
}

export function TableTextFilterModal<T>(props: { textFilter: TextFilter<T>, onClose: () => void, onSubmit: (queryFilter: QueryFilter<T>) => void }) {
  const [stateValue, setStateValue] = useState('');

  /* Validation */
  function validateValue() {
    if (!stateValue) return [];
    return validate(stateValue, [VALIDATORS.length(stateValue, 1, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateValue) { required.push(`${props.textFilter.name} is required`) }
    return [
      ...required,
      ...validateValue(),
    ];
  }

  return (
    <div className="card card-compact">
      <div className="card-body bg-base-100 flex rounded-xl">
        <span className="flex justify-between items-center bg-base-100">
          <span><div className='text-lg'>Filter '{props.textFilter.name}'</div></span>
          <button className="btn btn-sm btn-circle btn-ghost" onClick={props.onClose}>✕</button>
        </span>
        <InputText labelText='' value={stateValue} onChange={(event) => { setStateValue(event.target.value) }} errors={validateValue()} placeholder={filterQueries.textQueries[props.textFilter.query]} > </InputText>
        <span className="flex justify-end items-center">
          <InputButton text='Apply' loading={false} disabled={validateForm().length > 0} type='btn-primary' onClick={() => { props.onSubmit({ id: props.textFilter.id as any, query: props.textFilter.query, value: stateValue }) }}></InputButton>
        </span>
      </div>
    </div>
  );
}