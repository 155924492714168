import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { users } from "./bean_user";
import { relations } from "drizzle-orm";

export const linkOrganizationUsers = sqliteTable('link_organization_users', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidUser: text('uuid_user').notNull().references(() => users.uuid),
});

export const linkOrganizationUsersRelations = relations(linkOrganizationUsers, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkOrganizationUsers.uuidOrganization],
    references: [organizations.uuid],
  }),
  user: one(users, {
    fields: [linkOrganizationUsers.uuidUser],
    references: [users.uuid],
  }),
}));