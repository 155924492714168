import { FC, useContext, useState } from 'react';
import { InputButton } from "../../components/input_button";
import { InputForm } from "../../components/input_form";
import { Modal, ModalProps } from "../../components/modal";
import { TextHeader } from "../../components/text_header";
import { ContextUser } from '../../providers/provider_user';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { VALIDATORS, validate } from '../../validation';
import { StatusAlert } from '../../components/status_alert';
import { TextDescription } from '../../components/text_description';
import { InputText } from '../../components/input_text';
import { ContextToast } from '../../providers/provider_toast';
import { useCaller } from '../../hooks/hook_caller';

interface ModalUpdateEmailProps extends ModalProps {
  onSubmit?: () => Promise<void>;
}

export const ModalUpdateEmail: FC<ModalUpdateEmailProps> = (props) => {
  const user = useContext(ContextUser);
  const vigil = useContext(ContextVigilClient);
  const toast = useContext(ContextToast);

  const [stateStep, setStep] = useState('request');
  const [stateEmail, setEmail] = useState(user.data?.email || '');
  const [stateOTP, setOTP] = useState('');

  const steps = {
    REQUEST: 'request',
    CONFIRM: 'confirm'
  }

  const { call: callEmailRequest, loading: loadingRequest, error: errorRequest } = useCaller({
    callback: () => vigil.functions.userEmailRequest({ email: stateEmail }),
    isMutation: true,
    onCalled: () => navigateStep(steps.CONFIRM)
  });

  const { call: callEmailConfirm, loading: loadingConfirm, error: errorConfirm } = useCaller({
    callback: async () => {
      await vigil.functions.userEmailConfirm({ email: stateEmail, otp: stateOTP });
      await user.refresh(true);
      props.toggle();
      navigateStep(steps.REQUEST);
      toast.addToast({
        component: <StatusAlert type='alert-success' message='Email address successfully updated.' />,
        lifetimeMS: 3000
      });
    },
    isMutation: true
  });

  /* UI Updates */
  function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value)
  }
  function onChangeOTP(event: React.ChangeEvent<HTMLInputElement>) {
    setOTP(event.target.value)
  }
  function navigateStep(step: string) {
    setOTP('');
    setStep(step);
  }

  /* Validation */
  function hasChanges() {
    const changed = stateEmail !== user.data!.email;
    return changed;
  }
  function validateEmail() {
    if (!stateEmail) return [];
    return validate(stateEmail, [VALIDATORS.email()]);
  }
  function validateOTP() {
    if (!stateOTP) return [];
    return validate(stateOTP, [VALIDATORS.length('OTP', 6, 6)]);
  }
  function validateFormRequest() {
    const required: string[] = [];
    if (!stateEmail) { required.push('Email is required') }

    return [
      ...required,
      ...validateEmail(),
    ];
  }
  function validateFormConfirm() {
    const required: string[] = [];
    if (!stateOTP) { required.push('OTP is required') }

    return [
      ...required,
      ...validateOTP(),
    ];
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-128">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <TextHeader> Update Email Address </TextHeader>
      {
        // STEP - Request OTP
        stateStep == steps.REQUEST &&
        <div>
          <div className='my-4'>
            <TextDescription> Please enter the new email address you wish to associate to your account.</TextDescription>
          </div>
          <InputForm onSubmit={callEmailRequest}>
            <InputText labelText='Email' value={stateEmail} onChange={onChangeEmail} errors={validateEmail()} />
            <div className='py-2'>
              {errorRequest && <StatusAlert type='alert-error' message={errorRequest} />}
            </div>
            <InputButton
              type='btn-primary'
              text='Continue'
              loading={loadingRequest}
              disabled={validateFormRequest().length > 0 || !hasChanges()}
              onClick={callEmailRequest}
            />
          </InputForm>
        </div>
      }
      {
        // STEP - Confirm OTP
        stateStep == steps.CONFIRM &&
        <div>
          <div className='my-6'>
            <StatusAlert type='alert-success' message={`OTP successfully sent to ${stateEmail}`} />
          </div>
          <div className='my-4'>
            <TextDescription> To confirm your new email address please enter the OTP sent to you in the input below. </TextDescription>
          </div>

          <InputForm onSubmit={callEmailConfirm}>
            <InputText labelText='OTP' value={stateOTP} onChange={onChangeOTP} errors={validateOTP()} />
            <div className='py-2'>
              {errorConfirm && <StatusAlert type='alert-error' message={errorConfirm} />}
            </div>
            <InputButton
              type='btn-primary'
              text='Save'
              loading={loadingConfirm}
              disabled={validateFormConfirm().length > 0}
              onClick={callEmailConfirm}
            />
            <div className='my-2'>
            </div>
            <InputButton
              type='btn-default'
              text='Back'
              onClick={() => navigateStep('request')}
            />
          </InputForm>
        </div>
      }
    </Modal>
  )
}