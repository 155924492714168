export function getOSName(navigator: Navigator) {
  if (navigator.userAgent.match(/win/i)) return "Windows";
  else if (navigator.userAgent.match(/mac/i)) return "MacOS";
  else if (navigator.userAgent.match(/linux/i)) return "Linux";
  else if (navigator.userAgent.match(/iphone|ipad|ipod/i)) return "iOS";
  else if (navigator.userAgent.match(/android/i)) return "Android";
  return "Unknown";
};

export function getBrowserName(navigator: Navigator) {
  if (navigator.userAgent.match(/chrome|chromium|crios/i)) return "Chrome";
  else if (navigator.userAgent.match(/firefox|fxios/i)) return "Firefox";
  else if (navigator.userAgent.match(/safari/i)) return "Safari";
  else if (navigator.userAgent.match(/opr\//i)) return "Opera";
  else if (navigator.userAgent.match(/edg/i)) return "Edge";
  else if (navigator.userAgent.match(/msie|trident/i)) return "Internet Explorer";
  return "Unknown";
};
