import { integer, sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { relations } from "drizzle-orm";

export const organizationRoles = sqliteTable('organization_roles', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  name: text('name').notNull(),
  permissionString: text('permission_string').notNull(),
  mutable: integer('mutable').notNull(),
});

export const organizationRolesRelations = relations(organizationRoles, ({ one }) => ({
  organization: one(organizations, {
    fields: [organizationRoles.uuidOrganization],
    references: [organizations.uuid],
  }),
}));