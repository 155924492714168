export const filterQueries = {
  textQueries: {
    startsWith: 'Starts With',
    endsWith: 'Ends With',
    contains: 'Contains',
    equals: 'Equals',
    notEquals: 'Not Equals',
    isNull: 'Is Null',
    isNotNull: 'Is Not Null',
    inList: 'In List',
    notInList: 'Not In List',
  },
  numberQueries: {
    equals: 'Equals',
    notEquals: 'Not Equals',
    greaterThan: 'Greater Than',
    lessThan: 'Less Than',
    greaterThanOrEqual: 'Greater Than Or Equal',
    lessThanOrEqual: 'Less Than Or Equal',
    between: 'Between',
    isNull: 'Is Null',
    isNotNull: 'Is Not Null',
    inList: 'In List',
    notInList: 'Not In List',
  },
  booleanQueries: {
    equals: 'Equals',
    notEquals: 'Not Equals',
    isNull: 'Is Null',
    isNotNull: 'Is Not Null',
  },
  dateQueries: {
    equals: 'Equals',
    notEquals: 'Not Equals',
    greaterThan: 'Greater Than',
    lessThan: 'Less Than',
    greaterThanOrEqual: 'Greater Than Or Equal',
    lessThanOrEqual: 'Less Than Or Equal',
    between: 'Between',
    isNull: 'Is Null',
    isNotNull: 'Is Not Null',
    inList: 'In List',
    notInList: 'Not In List',
  },
};

type FilterQueryType = typeof filterQueries;

export type TextQueries = keyof FilterQueryType['textQueries'];
export type NumberQueries = keyof FilterQueryType['numberQueries'];
export type BooleanQueries = keyof FilterQueryType['booleanQueries'];
export type DateQueries = keyof FilterQueryType['dateQueries'];

export type AllQueries = TextQueries | NumberQueries | BooleanQueries | DateQueries;

export function isTextQuery(query: string): query is TextQueries {
  return query in filterQueries.textQueries;
}
export function isNumberQuery(query: string): query is NumberQueries {
  return query in filterQueries.numberQueries;
}
export function isBooleanQuery(query: string): query is BooleanQueries {
  return query in filterQueries.booleanQueries;
}
export function isDateQuery(query: string): query is DateQueries {
  return query in filterQueries.dateQueries;
}

export interface BaseFilter {
  id: string;
  name: string;
  query: AllQueries;
}

export interface QueryFilter<T> {
  id: keyof T;
  query: AllQueries;
  value: string | number | boolean | Date | { startDate: Date | null, endDate: Date | null } | (string | number | Date)[];
}