import React, { useContext } from 'react';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextUser } from '../../providers/provider_user';
import { IOrganizationSelect, IProfileResult } from 'vigil-datamodel';
import { StatusLoading } from '../../components/status_loading';
import { StatusAlert } from '../../components/status_alert';
import { InputButtonAsync } from '../../components/input_button_async';
import { InputButton } from '../../components/input_button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { Preferences } from '../../preference_keys';
import { useCaller } from '../../hooks/hook_caller';
import { ContextOrganization } from '../../providers/provider_organization';

interface ScreenProfileOrganizationsProps { }

export const ScreenProfileOrganizations: React.FC<ScreenProfileOrganizationsProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const user = useContext(ContextUser);
  const organization = useContext(ContextOrganization);
  const navigate = useNavigate();

  const stateProfile = useCaller<IProfileResult>({
    callback: async () => {
      const result = await vigil.functions.userProfile({});
      return result;
    },
    dependencies: { background: [], normal: [user] },
    intervalTime: 5000
  });

  async function actionNewOrganization() {
    localStorage.removeItem(Preferences.SELECTED_ORGANIZATION);
    await organization.refresh(true);
    navigate(ROUTES.ROUTE_HOME_ONBOARDING_NEW_ORGANIZATION)
  }

  async function actionSelectOrganization(org: IOrganizationSelect) {
    localStorage.setItem(Preferences.SELECTED_ORGANIZATION, org.uuid);
    await organization.refresh(true);
    navigate(ROUTES.ROUTE_HOME)
  }

  async function actionAcceptInvite(uuidUserOrganizationRoleInvite: string) {
    await vigil.functions.userOrganizationRoleInviteAccept({ uuidUserOrganizationRoleInvite });
    stateProfile.call();
  }

  async function actionRejectInvite(uuidUserOrganizationRoleInvite: string) {
    await vigil.functions.userOrganizationRoleInviteReject({ uuidUserOrganizationRoleInvite });
    stateProfile.call();
  }

  return (
    <div className='py-4'>
      <div className='rounded-t-xl bg-base-300 p-2'>
        <div className='flex items-center'>
          <div className='font-semibold flex flex-grow'>Organizations</div>
          <InputButton text='New Organization' type='btn-primary' size='btn-sm' onClick={() => { actionNewOrganization() }} />
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2'>
        {
          stateProfile.loading &&
          <div className='flex justify-center my-4'>
            <StatusLoading />
          </div>
        }
        {
          !stateProfile.loading && stateProfile.error &&
          <div>
            <StatusAlert message={stateProfile.error} type='alert-error' />
          </div>
        }
        {
          !stateProfile.loading && !stateProfile.error &&
          <div className='m-2'>
            {
              !stateProfile.result || stateProfile.result.organizations.length == 0 &&
              <div>
                You have no linked organizations
                <br />
                You can either create a new organization, or asked to be invited to an existing organization
              </div>
            }
            {
              stateProfile.result && stateProfile.result.organizations.length > 0 &&
              stateProfile.result.organizations.map((organization) => {
                return <div className='flex p-2 border-solid border-b border-base-300 link hover:bg-base-300 rounded' key={organization.uuid} onClick={() => actionSelectOrganization(organization)}>
                  <div className='mr-2'>
                    <img src={`https://ui-avatars.com/api/?name=${organization?.name[0]}&background=random`} className="w-8 h-8 rounded-full" alt="" />
                  </div>
                  <div className='flex-1'>
                    {organization.name}
                  </div>
                </div>
              })
            }
          </div>
        }
      </div>

      <div className='h-4' />

      <div className='rounded-t-xl bg-base-300 p-2'>
        <div className='flex items-center'>
          <div className='font-semibold'>Organization Invites</div>
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2'>
        {
          stateProfile.loading &&
          <div className='flex justify-center my-4'>
            <StatusLoading />
          </div>
        }
        {
          !stateProfile.loading && stateProfile.error &&
          <div>
            <StatusAlert message={stateProfile.error} type='alert-error' />
          </div>
        }
        {
          !stateProfile.loading && !stateProfile.error &&
          <div className='m-2'>
            {
              !stateProfile.result || stateProfile.result.organizationInvites.length == 0 &&
              <div>
                You have no pending organization invites.
                <br /><br />
                If you wish to join an organization, ask the administrator to send you an invite.
              </div>
            }
            {
              stateProfile.result && stateProfile.result.organizationInvites.length > 0 &&
              stateProfile.result.organizationInvites.map((item) => {
                return <div className='flex p-2 border-solid border-b border-base-300' key={item.organization.uuid}>
                  <div className='mr-2'>
                    <img src={`https://ui-avatars.com/api/?name=${item.organization?.name[0]}&background=random`} className="w-8 h-8 rounded-full" alt="" />
                  </div>
                  <div className='flex-1'>
                    {item.organization.name}
                  </div>
                  <div className='flex'>
                    <InputButtonAsync
                      text='Accept' type='btn-success' size='btn-sm'
                      asyncAction={async () => { await actionAcceptInvite(item.uuid) }}
                    />
                    <div className='mr-1' />
                    <InputButtonAsync
                      text='Decline' type='btn-error' size='btn-sm'
                      asyncAction={async () => { await actionRejectInvite(item.uuid) }}
                    />
                  </div>
                </div>
              })
            }
          </div>
        }
      </div>
    </div>
  );
};
