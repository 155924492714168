import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { linkOrganizationSites } from "./bean_link_organization_site";
import { linkSiteBeacons } from "./bean_link_site_beacon";

export const sites = sqliteTable('sites', {
  ...schemaBase(),
  name: text('name').notNull(),
});

export const sitesRelations = relations(sites, ({ many }) => ({
  sitesToOrganizations: many(linkOrganizationSites),
  sitesToBeacons: many(linkSiteBeacons),
}));