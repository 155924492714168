import { ReactElement, createContext, useContext } from "react";
import { Preferences } from "../preference_keys";
import { ContextVigilClient } from "./provider_vigil_client";
import { TTuuid } from "tt-uuid";
import { DashActivityLogTypeData } from "vigil-datamodel";
import { getBrowserName, getOSName } from "./func_browser_info";
import { useCaller } from "../hooks/hook_caller";
import { FullPageError } from "../components/full_page_error";

interface ISessionContext {
  logEvent(info: DashActivityLogTypeData): void;
}

interface ProviderSessionProps {
  children: ReactElement
}

export const ContextSession = createContext(null as null | ISessionContext);
export const ProviderSession: React.FC<ProviderSessionProps> = (props: ProviderSessionProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);

  async function fetchDashSessionUuid() {
    const uuidSession = localStorage.getItem(Preferences.UUID_SESSION) ?? TTuuid.getCuuid();
    await vigil.functions.dashSessionUpsert({ uuid: uuidSession, browserType: getBrowserName(navigator), operatingSystem: getOSName(navigator) })
    localStorage.setItem(Preferences.UUID_SESSION, uuidSession);
    return uuidSession;
  }

  /* State */
  const dash = useCaller({
    callback: async () => {
      const sessionUuid = await fetchDashSessionUuid();
      const connection = (navigator as any)['connection'] || null;
      await vigil.functions.dashStateLogCreate({
        info: {
          uuidSession: sessionUuid,
          effectiveType: connection?.effectiveType,
          roundTripTime: connection?.rtt,
          downlink: connection?.downlink,
          status: navigator.onLine ? 'online' : 'offline'
        }
      });
    },
    intervalTime: 1000 * 60 * 10 // 10 minutes
  });

  if (dash.error) return <FullPageError error={dash.error} />

  const logEvent: ISessionContext['logEvent'] = async (info) => {
    try {
      const uuidSession = await fetchDashSessionUuid();
      await vigil.functions.dashActivityLogCreate({
        info: {
          uuidSession,
          ...info
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ContextSession.Provider value={{ logEvent }}>
      {props.children}
    </ContextSession.Provider>
  )
}
