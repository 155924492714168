import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { dashSessions } from "./bean_dash_session";
import { relations } from "drizzle-orm";

export enum DashActivityLogType {
  UserLogin = 'userLogin',
  UserLogout = 'userLogout',
  OrgLogin = 'orgLogin',
  OrgLogout = 'orgLogout',
  OrgSwitch = 'orgSwitch',
  GenericText = 'genericText',
}

export interface IDashActivityLogUserLoginLogout {
  userUuid: string,
}

export interface IDashActivityLogOrgLoginLogout {
  orgUuid: string,
}

export interface IDashActivityOrgSwitch {
  fromUuid: string,
  toUuid: string,
}

export interface IDashActivityLogGenericText {
  title: string,
  description: string,
  tags: string[],
  data: Record<string, any>,
}

export type DashActivityLogTypeData = (
  | { type: DashActivityLogType.UserLogin, data: IDashActivityLogUserLoginLogout }
  | { type: DashActivityLogType.UserLogout, data: IDashActivityLogUserLoginLogout }
  | { type: DashActivityLogType.OrgLogin, data: IDashActivityLogOrgLoginLogout }
  | { type: DashActivityLogType.OrgLogout, data: IDashActivityLogOrgLoginLogout }
  | { type: DashActivityLogType.OrgSwitch, data: IDashActivityOrgSwitch }
  | { type: DashActivityLogType.GenericText, data: IDashActivityLogGenericText }
)

export const dashActivityLogs = sqliteTable('dash_activity_logs', {
  ...schemaBase(),
  uuidSession: text('uuid_session').notNull().references(() => dashSessions.uuid),
  type: text('type', { enum: Object.values(DashActivityLogType) as [DashActivityLogType] }).notNull(),
  data: customJsonColumn<IDashActivityLogUserLoginLogout | IDashActivityLogOrgLoginLogout | IDashActivityOrgSwitch | IDashActivityLogGenericText>('data').notNull(),
});

export const dashActivityLogsRelations = relations(dashActivityLogs, ({ one }) => ({
  session: one(dashSessions, {
    fields: [dashActivityLogs.uuidSession],
    references: [dashSessions.uuid],
  }),
}));
