import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IBeaconSelect, ISiteSelect, BeaconType } from 'vigil-datamodel';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { VigilMap, VigilMapController } from '../../components/vigil_map';
import { VigilMapMessageId, VigilMapV2Props, VigilMapBeacon, getBoundingBox, VigilMapV2ThemeId } from 'vigil-map';
import { IconBluetoothOutline, IconChevronRightSolid, IconMapPinAltOutline, IconNFCOutline, IconQrCodeSolid, IconWifiOutline } from '../../components/icons';
import { TTuuid } from 'tt-uuid';
import useBreakpoints from '../../hooks/hook_breakpoints';
import { ContextTheme } from '../../providers/provider_theme';

interface SiteViewProps {
  site: ISiteSelect | undefined;
  beacons: IBeaconSelect[] | undefined;
  isLoading: boolean;
  error: string;
  onBeaconSelect?: (beacon: IBeaconSelect) => void;
  breadcrumbs: Array<{ id: string; onClick: () => void; text: string }>;
  additionalActions?: React.ReactNode;
  isPublic?: boolean;
  children?: React.ReactNode;
}

const SiteView: React.FC<SiteViewProps> = ({
  site,
  beacons,
  isLoading,
  error,
  onBeaconSelect,
  breadcrumbs,
  additionalActions,
  isPublic = false,
  children,
}) => {
  const navigate = useNavigate();
  const theme = useContext(ContextTheme);
  const breakpoints = useBreakpoints();

  const [stateSnapped, setSnapped] = useState(false);
  const [stateMapController, setMapController] = useState<VigilMapController | null>(null);
  const [stateMapProps, setMapProps] = useState<VigilMapV2Props>({});
  const [stateSelectedBeacon, setSelectedBeacon] = useState<IBeaconSelect | undefined>();
  const [stateBeaconMarkers, setBeaconMarkers] = useState<VigilMapBeacon[]>([]);

  useEffect(() => {
    if (beacons) {
      setBeaconMarkers(beacons.map((beacon) => ({
        uuid: beacon.uuid,
        lat: beacon.latitude,
        lng: beacon.longitude,
        name: beacon.name,
        color: 'red'
      })));
    }
  }, [beacons]);

  useEffect(() => {
    setMapProps({
      beacons: stateBeaconMarkers,
      zoomControl: breakpoints['MD'] ? true : false,
      theme: theme.data === 'dark' ? VigilMapV2ThemeId.DARK_MATTER : VigilMapV2ThemeId.BASIC,
    });
    if (stateMapController && stateBeaconMarkers.length > 0 && !stateSnapped) {
      stateMapController?.sendVigilMessage({
        id: VigilMapMessageId.FIT_BOUNDS,
        bounds: getBoundingBox(stateBeaconMarkers.map((beacon) => ({ lat: beacon.lat, lng: beacon.lng }))),
        options: {
          maxZoom: 18,
          padding: {
            left: 350,
          }
        }
      });
      setSnapped(true);
    }
  }, [stateBeaconMarkers, stateMapController, breakpoints, theme]);

  function toggleSelectedBeacon(beacon?: IBeaconSelect) {
    if (stateSelectedBeacon === beacon || !beacon) {
      setSelectedBeacon(undefined);
      stateMapController?.sendVigilMessage({
        id: VigilMapMessageId.FIT_BOUNDS,
        bounds: getBoundingBox(stateBeaconMarkers.map((beacon) => ({ lat: beacon.lat, lng: beacon.lng }))),
        options: {
          maxZoom: 18,
          padding: {
            left: 350,
          }
        }
      });
    } else {
      setSelectedBeacon(beacon);
      stateMapController?.sendVigilMessage({
        id: VigilMapMessageId.FIT_BOUNDS,
        bounds: getBoundingBox(stateBeaconMarkers.map((beacon) => ({ lat: beacon.lat, lng: beacon.lng }))),
        options: {
          maxZoom: 18,
          padding: {
            left: 600,
          }
        }
      });
    }

    const tempBeaconMarkers = stateBeaconMarkers.map((beaconMarker) => ({
      ...beaconMarker,
      color: beaconMarker.uuid === beacon?.uuid ? 'green' : 'red'
    }));
    setBeaconMarkers(tempBeaconMarkers as VigilMapBeacon[]);

    if (onBeaconSelect && beacon) {
      onBeaconSelect(beacon);
    }
  }

  if (isLoading || !site) return <FullPageLoader />;
  if (error) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={error} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full flex flex-col h-full'>
      <Breadcrumbs crumbs={breadcrumbs} />

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <div className='flex items-center'>
        <div className='flex flex-grow items-end'>
          <div className="flex items-center mr-6">
            <span className='font-semibold'>Date Created:</span>
            <span className="text-sm ml-2">{TTuuid.decodeCuuid(site.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(site.uuid).time.toLocaleTimeString()}</span>
          </div>
          <div className="flex items-center mr-6">
            <span className='font-semibold'>Last Updated:</span>
            <span className="text-sm ml-2">{TTuuid.decodeCuuid(site.changeStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(site.changeStamp).time.toLocaleTimeString()}</span>
          </div>
        </div>
        {additionalActions}
      </div>

      <div className="card drop-shadow-md flex flex-col flex-grow py-2">
        <div className='flex-grow rounded-xl overflow-hidden'>
          <VigilMap setMapController={setMapController} state={stateMapProps} />
        </div>

        <div className="card bg-opacity-0 backdrop-blur-sm w-80 m-2 inset-0 absolute">
          <div className='rounded-t-xl bg-base-200 bg-opacity-40'>
            <div className='flex justify-center font-bold mt-2'>Linked Beacons</div>
            {children}
          </div>

          <div className='rounded-b-xl bg-base-100 bg-opacity-20 p-2 flex-grow overflow-y-auto'>
            {beacons && beacons.map((beacon) => (
              <div key={beacon.uuid} className="relative">
                <div onClick={() => toggleSelectedBeacon(beacon)} className={`card dropdown dropdown-right w-full hover:cursor-pointer hover:bg-base-200 flex flex-row items-center p-2 drop-shadow-xl mb-2 ${stateSelectedBeacon === beacon ? 'bg-base-200 dropdown-open' : 'bg-base-100 dropdown-close'}`}>
                  {beacon.type === BeaconType.QR && <IconQrCodeSolid className='h-5 mr-2' />}
                  {beacon.type === BeaconType.GEO && <IconMapPinAltOutline className='h-5 mr-2 stroke-base-content' />}
                  {beacon.type === BeaconType.BLE && <IconBluetoothOutline className='h-5 mr-2 stroke-base-content' />}
                  {beacon.type === BeaconType.NFC && <IconNFCOutline className='h-5 mr-2 stroke-base-content' />}
                  {beacon.type === BeaconType.WIFI && <IconWifiOutline className='h-5 mr-2' />}
                  <div className='flex flex-col flex-grow text-left'>
                    <span className='text-md font-semibold'>{beacon.name}</span>
                    <span className='text-xs'>{beacon.latitude.toFixed(5)}, {beacon.longitude.toFixed(5)}</span>
                  </div>
                  <IconChevronRightSolid className={`h-5 transform transition-transform duration-300 ${stateSelectedBeacon === beacon ? 'rotate-180' : ''}`} />
                </div>
              </div>
            ))}
          </div>

          {stateSelectedBeacon && (
            <div onClick={(event) => event.stopPropagation()} className="absolute left-full ml-3 mt-20 p-2 shadow dropdown-content z-[1] bg-base-100 rounded-box w-64 outline outline-1 outline-neutral-content">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1" onClick={() => toggleSelectedBeacon()}>✕</button>
              <div className='text-lg font-semibold mb-2 text-center'>{stateSelectedBeacon.name}</div>
              <div className='text-left'>
                <div className='text-xs'>UUID: {stateSelectedBeacon.uuid}</div>
                <div className='text-xs'>Latitude: {stateSelectedBeacon.latitude.toFixed(6)}</div>
                <div className='text-xs'>Longitude: {stateSelectedBeacon.longitude.toFixed(6)}</div>
                <div className='text-xs'>Altitude: {stateSelectedBeacon.altitude}m</div>
                <div className='text-xs'>Radius: {stateSelectedBeacon.radius}m</div>
                <div className='text-xs'>Type: {stateSelectedBeacon.type}</div>
                <div className='text-xs'>Date created: {TTuuid.decodeCuuid(stateSelectedBeacon.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateSelectedBeacon.uuid).time.toLocaleTimeString()}</div>
                <div className='text-xs mb-4'>Last updated: {TTuuid.decodeCuuid(stateSelectedBeacon.changeStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateSelectedBeacon.changeStamp).time.toLocaleTimeString()}</div>
                {!isPublic && (
                  <button
                    className="btn btn-primary btn-sm w-full"
                    onClick={() => navigate(`/home/beacon/${stateSelectedBeacon.uuid}`)}
                  >
                    Edit Beacon
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SiteView;