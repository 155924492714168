import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { organizationRoles } from "./bean_organization_role";
import { users } from "./bean_user";
import { relations } from "drizzle-orm";

export const organizationRoleUserInvite = sqliteTable('link_organization_role_user_invite', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidOrganizationRole: text('uuid_organization_role').references(() => organizationRoles.uuid),
  inviteUserUuid: text('invite_user_uuid').references(() => users.uuid),
  inviteUserEmail: text('invite_user_email'),
  inviteUserMobile: text('invite_user_mobile'),
});

export const organizationRoleUserInvitesRelations = relations(organizationRoleUserInvite, ({ one }) => ({
  organization: one(organizations, {
    fields: [organizationRoleUserInvite.uuidOrganization],
    references: [organizations.uuid],
  }),
  role: one(organizationRoles, {
    fields: [organizationRoleUserInvite.uuidOrganizationRole],
    references: [organizationRoles.uuid],
  }),
  inviteUser: one(users, {
    fields: [organizationRoleUserInvite.inviteUserUuid],
    references: [users.uuid],
  }),
}));