import { useContext, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { InputSelect, OptionSelect } from "../../../components/input_select";
import { IActionSelect } from "vigil-datamodel";
import { ActionStep, ActionStepEmail, ActionStepType, ActionTrigger, ActionTriggerType, ActionTriggerSchedule } from "vigil-datamodel/src/bean_action";
import { actionStepOptions, actionTriggerOptions } from "./helper_action";
import { ActionTriggerScheduleComponent } from "./action_trigger_schedule";
import { ActionStepEmailComponent } from "./action_step_email";

interface ModalActionCreateProps extends ModalProps {
  onSubmit?: (action: IActionSelect) => Promise<void>;
}

export const ModalActionCreate: React.FC<ModalActionCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');

  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  const [stateName, setName] = useState<string>('');
  const [stateAddedTriggerOptions, setAddedTriggerOptions] = useState<OptionSelect[]>([]);
  const [stateAddedStepOptions, setAddedStepOptions] = useState<OptionSelect[]>([]);

  const [stateTriggers, setTriggers] = useState<ActionTrigger[]>([]);
  const [stateValidateTriggers, setValidateTriggers] = useState<string[]>([]);
  const [stateSteps, setSteps] = useState<ActionStep[]>([]);
  const [stateValidateSteps, setValidateSteps] = useState<string[]>([]);

  /* DB Functions */
  async function createAction() {
    try {
      if (!organization.data) throw new Error('Organization not found');
      setError('');
      setSubmitLoading(true);
      const action = await vigil.functions.createOneOnOrganization({
        type: 'actions',
        uuidOrganization: organization.data.uuid,
        data: {
          uuidOrganization: organization.data.uuid,
          name: stateName,
          enabled: 1,
          triggers: stateTriggers,
          steps: stateSteps,
        }
      });
      props.onSubmit && await props.onSubmit(action);
      props.toggle();
      resetState();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  /* Functions */
  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function addTriggerOption() {
    setAddedTriggerOptions([...stateAddedTriggerOptions, actionTriggerOptions[0]]);
  }

  function removeTriggerOption(index: number) {
    const newAddedTriggers = [...stateAddedTriggerOptions];
    newAddedTriggers.splice(index, 1);
    setAddedTriggerOptions(newAddedTriggers);
    setTriggers(stateTriggers.filter((trigger, i) => i !== index));
  }

  function onChangeTriggerOption(index: number, event: React.ChangeEvent<HTMLSelectElement>) {
    const triggerOption = actionTriggerOptions.find((triggerOption) => triggerOption.value == parseInt(event.target.value));
    if (triggerOption) {
      const newAddedTriggers = [...stateAddedTriggerOptions];
      newAddedTriggers[index] = triggerOption
      setAddedTriggerOptions(newAddedTriggers);
    }
  }

  function onChangeTrigger(index: number, trigger: ActionTrigger) {
    const newTriggers = [...stateTriggers];
    newTriggers[index] = trigger;
    setTriggers(newTriggers);
  }

  function onChangeTriggerValidation(index: number, errors: string[]) {
    const newValidateTriggers = [...stateValidateTriggers];
    newValidateTriggers[index] = errors.join(', ');
    setValidateTriggers(newValidateTriggers.filter((error) => error.length > 0));
  }

  function addStepOption() {
    setAddedStepOptions([...stateAddedStepOptions, actionStepOptions[0]]);
  }

  function removeStepOption(index: number) {
    const newAddedSteps = [...stateAddedStepOptions];
    newAddedSteps.splice(index, 1);
    setAddedStepOptions(newAddedSteps);
    setSteps(stateSteps.filter((step, i) => i !== index));
  }

  function onChangeStepOption(index: number, event: React.ChangeEvent<HTMLSelectElement>) {
    const stepOption = actionStepOptions.find((stepOption) => stepOption.value == parseInt(event.target.value));
    if (stepOption) {
      const newAddedSteps = [...stateAddedStepOptions];
      newAddedSteps[index] = stepOption;
      setAddedStepOptions(newAddedSteps);
    }
  }

  function onChangeStep(index: number, step: ActionStep) {
    const newSteps = [...stateSteps];
    newSteps[index] = step;
    setSteps(newSteps);
  }

  function onChangeStepValidation(index: number, errors: string[]) {
    const newValidateSteps = [...stateValidateSteps];
    newValidateSteps[index] = errors.join(', ');
    setValidateSteps(newValidateSteps);
  }

  function resetState() {
    setName('');
    setAddedTriggerOptions([]);
    setAddedStepOptions([]);
    setTriggers([]);
    setSteps([]);
  }

  /* Validation */
  function validateName() {
    if (!stateName) return [];
    return validate(stateName, [VALIDATORS.length('Action name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateName) { required.push('Action name is required') }
    if (stateAddedTriggerOptions.length == 0) { required.push('Action triggers are required') }
    if (stateAddedStepOptions.length == 0) { required.push('Action steps are required') }
    return [
      ...required,
      ...validateName(),
      ...stateValidateTriggers,
      ...stateValidateSteps,
    ].filter((error) => error.length > 0);
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-192">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Action</h3>
      <InputText className="w-60 mb-2 mr-4" labelText='Action name' value={stateName} onChange={onChangeName} errors={validateName()} > </InputText>
      <div className="overflow-y-auto">
        <h3 className="font-bold text-sm mb-2">Triggers</h3>
        {stateAddedTriggerOptions.map((trigger, index) => {
          return (
            <div key={index + stateAddedTriggerOptions.length} className="mb-2 mr-4 bg-base-200 p-1 rounded-xl relative">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => removeTriggerOption(index)}>✕</button>
              <InputSelect className="w-48 mb-2 mr-4" errors={[]} labelText="Trigger Option" value={trigger.value} options={actionTriggerOptions} onChange={(event) => onChangeTriggerOption(index, event)}> </InputSelect>
              {trigger.value === ActionTriggerType.Scheduled && <ActionTriggerScheduleComponent
                trigger={stateTriggers[index] as ActionTriggerSchedule}
                setTrigger={(trigger) => onChangeTrigger(index, trigger)}
                setValidateForm={(errors) => onChangeTriggerValidation(index, errors)} />}
            </div>
          )
        })}
        <div className="flex mb-2">
          <InputButton text='+ Add Trigger' type='btn-primary' onClick={addTriggerOption}></InputButton>
        </div>
        <h3 className="font-bold text-sm mb-2">Steps</h3>
        {stateAddedStepOptions.map((step, index) => {
          return (
            <div key={index + stateAddedStepOptions.length} className="mb-2 mr-4 bg-base-200 p-1 rounded-xl relative">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => removeStepOption(index)}>✕</button>
              <InputSelect className="w-48 mb-2 mr-4" errors={[]} labelText="Step Option" value={step.value} options={actionStepOptions} onChange={(event) => onChangeStepOption(index, event)}> </InputSelect>
              {step.value === ActionStepType.Email && <ActionStepEmailComponent
                step={stateSteps[index] as ActionStepEmail}
                setStep={(step) => onChangeStep(index, step)}
                setValidateForm={(errors) => onChangeStepValidation(index, errors)} />}
            </div>
          )
        })}
        <div className="flex mb-2">
          <InputButton text='+ Add Step' type='btn-primary' onClick={addStepOption}></InputButton>
        </div>
      </div>
      {stateError && <StatusAlert message={stateError} type={"alert-error"} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={stateSubmitLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await createAction()}></InputButton>
      </div>
    </Modal>
  )
}