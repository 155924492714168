import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';

interface ScreenAdminProps { }

export const ScreenAdmin: React.FC<ScreenAdminProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className='h-full flex flex-col'>
      <div className='h-full flex'>
        <div className='h-full'>
          <div className='text-center my-2'>
            VIGIL ADMIN TOOL
          </div>
          <ul className="menu bg-base-200 w-56 rounded-box">
            <li><a onClick={() => navigate(ROUTES.ROUTE_ADMIN_BEACONS)} className={ROUTES.normalize(location.pathname) == ROUTES.ROUTE_ADMIN_BEACONS ? 'active' : ''}>Beacons</a></li>
            <li><a onClick={() => navigate(ROUTES.ROUTE_ADMIN_DEVICES)} className={ROUTES.normalize(location.pathname) == ROUTES.ROUTE_ADMIN_DEVICES ? 'active' : ''}>Devices</a></li>
          </ul>
        </div>
        <div className='h-full w-full flex overflow-scroll'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
