import React, { useContext, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IDirectoryReport, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/hook_modal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { ModalReportCreate } from './func_report/modal_report_create';
import { useTableData } from '../../hooks/hook_table_data';
import { ModalDeleteMany } from '../../components/modal_delete_many';

interface ScreenHomeReportsProps { }

export const ScreenHomeReports: React.FC<ScreenHomeReportsProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const vigil = useContext(ContextVigilClient);

  const { isOpen: isOpenModalCreateReport, toggle: toggleModalCreateReport } = useModal();
  const { isOpen: isOpenModalDeleteReport, toggle: toggleModalDeleteReport } = useModal();

  const [selectedReportsState, setSelectedReports] = useState<IDirectoryReport[]>([]);

  const { data: reports, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryReport>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryReports({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateReport(selectedReport: IDirectoryReport) {
    navigate(ROUTES.ROUTE_HOME_REPORT.replace(':uuid', selectedReport.uuid))
  }

  function deleteReports(selectedReports: IDirectoryReport[]) {
    setSelectedReports(selectedReports)
    toggleModalDeleteReport();
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalReportCreate isOpen={isOpenModalCreateReport} toggle={toggleModalCreateReport} onSubmit={forceRefetch} />
      <ModalDeleteMany
        isOpen={isOpenModalDeleteReport}
        toggle={toggleModalDeleteReport}
        type='reports'
        data={selectedReportsState.map(report => ({ uuid: report.uuid, label: report.name }))}
        onSubmit={forceRefetch}
      />

      <Table<IDirectoryReport>
        className='h-full flex flex-col drop-shadow'
        name='Reports'
        data={reports}
        selectable={true}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'name',
            header: 'Name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateReport(item)}>
                  {item.name}
                </div>
              );
            },
          },
          {
            id: 'config.type',
            header: 'Type',
            sortable: true,
            value(item) {
              return <div>{item.config.type.toUpperCase()}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'changeStamp',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('name', 'Name', 'contains'),
          new TextFilter('config.type', 'Type', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
          new DateFilter('changeStamp', 'Last Updated', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}

        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new report</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RReport),
            onClick: () => toggleModalCreateReport(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RReport),
            onClick: (selectedItems) => deleteReports(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RReport),
            onClick: (selectedItems) => deleteReports(selectedItems)
          }
        ]}
      />
    </div >
  );
};
