import { useContext } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { ContextOrganization } from "../../../providers/provider_organization";

interface ModalSiteBeaconsDeleteProps extends ModalProps {
  uuidSite: string;
  uuidBeacons: string[];
  onSubmit?: () => Promise<void>;
}

export const ModalSiteBeaconsDelete: React.FC<ModalSiteBeaconsDeleteProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  /* DB Functions */
  async function deleteLinks() {
    try {
      if (!organization.data) return;
      await vigil.functions.deleteLink({ type: { link: 'site', to: 'beacons' }, uuidOrganization: organization.data.uuid, uuidLink: props.uuidSite, uuidsTo: props.uuidBeacons });
      props.onSubmit && await props.onSubmit();
      props.toggle();
    } catch (error: any) {
      console.log(error.message);
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-128">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">{props.uuidBeacons.length > 1 ? 'Unlink Beacons' : 'Unlink Beacon'}</h3>
      <div>Are you sure you want to unlink the selected {props.uuidBeacons.length > 1 ? 'beacons' : 'beacon'}? This will:</div>
      <ul className="list-disc list-inside">
        <li>Remove the site-beacon links, but not the beacons or site.</li>
      </ul>
      <div className="flex justify-end pt-4">
        <InputButton text='Unlink' loading={false} disabled={false} type='btn-error' onClick={async () => await deleteLinks()}></InputButton>
      </div>
    </Modal>
  )
}