import React, { useState } from 'react';
import { useModal } from '../../hooks/hook_modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { InputButton } from '../../components/input_button';
import { IconPencilSquareSolid, IconTrashSolid } from '../../components/icons';
import { IActionSelect } from 'vigil-datamodel';
import { InputText } from '../../components/input_text';
import { VALIDATORS, validate } from '../../validation';
import { InputSelect, OptionSelect } from '../../components/input_select';
import { ActionStep, ActionStepEmail, ActionStepType, ActionTrigger, ActionTriggerType, ActionTriggerSchedule } from 'vigil-datamodel/src/bean_action';
import { ModalActionUpdate } from './func_action/modal_action_update';
import { actionStepOptions, actionTriggerOptions } from './func_action/helper_action';
import { ActionStepEmailComponent } from './func_action/action_step_email';
import { ActionTriggerScheduleComponent } from './func_action/action_trigger_schedule';
import { Action } from '@sentry/react/types/types';
import { ModalDeleteMany } from '../../components/modal_delete_many';
import { ROUTES } from '../../router/routes';

interface ScreenHomeActionOVerviewProps { }

export const ScreenHomeActionOVerview: React.FC<ScreenHomeActionOVerviewProps> = (props) => {
  const navigate = useNavigate()

  const { actionParent, fetchActionParent } = useOutletContext<{
    actionParent: IActionSelect,
    fetchActionParent: () => void
  }>();

  const [stateActionModify, setActionModify] = useState<IActionSelect>(actionParent);
  const [stateValidateTriggers, setValidateTriggers] = useState<string[]>([]);
  const [stateValidateSteps, setValidateSteps] = useState<string[]>([]);

  const [stateAddedTriggerOptions, setAddedTriggerOptions] = useState<OptionSelect[]>(
    stateActionModify.triggers.map((trigger) => actionTriggerOptions.find((triggerOption) => triggerOption.value == trigger.type) || actionTriggerOptions[0])
  );
  const [stateAddedStepOptions, setAddedStepOptions] = useState<OptionSelect[]>(
    stateActionModify.steps.map((step) => actionStepOptions.find((stepOption) => stepOption.value == step.type) || actionStepOptions[0])
  );

  const { isOpen: isOpenModalDeleteAction, toggle: toggleModalDeleteAction } = useModal();
  const { isOpen: isOpenModalUpdateAction, toggle: toggleModalUpdateAction } = useModal();

  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setActionModify({
      ...stateActionModify,
      name: event.target.value
    })
  }

  function addTriggerOption() {
    setAddedTriggerOptions([...stateAddedTriggerOptions, actionTriggerOptions[0]]);
  }

  function removeTriggerOption(index: number) {
    const newAddedTriggers = [...stateAddedTriggerOptions];
    newAddedTriggers.splice(index, 1);
    setAddedTriggerOptions(newAddedTriggers);
    setActionModify({
      ...stateActionModify,
      triggers: stateActionModify.triggers.filter((trigger, i) => i !== index)
    })
  }

  function onChangeTriggerOption(index: number, event: React.ChangeEvent<HTMLSelectElement>) {
    const triggerOption = actionTriggerOptions.find((triggerOption) => triggerOption.value == parseInt(event.target.value));
    if (triggerOption) {
      const newAddedTriggers = [...stateAddedTriggerOptions];
      newAddedTriggers[index] = triggerOption
      setAddedTriggerOptions(newAddedTriggers);
    }
  }

  function onChangeTrigger(index: number, trigger: ActionTrigger) {
    const newTriggers = [...stateActionModify.triggers];
    newTriggers[index] = trigger;
    setActionModify({
      ...stateActionModify,
      triggers: newTriggers
    });
  }

  function onChangeTriggerValidation(index: number, errors: string[]) {
    const newValidateTriggers = [...stateValidateTriggers];
    newValidateTriggers[index] = errors.join(', ');
    setValidateTriggers(newValidateTriggers.filter((error) => error.length > 0));
  }

  function addStepOption() {
    setAddedStepOptions([...stateAddedStepOptions, actionStepOptions[0]]);
  }

  function removeStepOption(index: number) {
    const newAddedSteps = [...stateAddedStepOptions];
    newAddedSteps.splice(index, 1);
    setAddedStepOptions(newAddedSteps);
    setActionModify({
      ...stateActionModify,
      steps: stateActionModify.steps.filter((step, i) => i !== index)
    });
  }

  function onChangeStepOption(index: number, event: React.ChangeEvent<HTMLSelectElement>) {
    const stepOption = actionStepOptions.find((stepOption) => stepOption.value == parseInt(event.target.value));
    if (stepOption) {
      const newAddedSteps = [...stateAddedStepOptions];
      newAddedSteps[index] = stepOption;
      setAddedStepOptions(newAddedSteps);
    }
  }

  function onChangeStep(index: number, step: ActionStep) {
    const newSteps = [...stateActionModify.steps];
    newSteps[index] = step;
    setActionModify({
      ...stateActionModify,
      steps: newSteps
    });
  }

  function onChangeStepValidation(index: number, errors: string[]) {
    const newValidateSteps = [...stateValidateSteps];
    newValidateSteps[index] = errors.join(', ');
    setValidateSteps(newValidateSteps);
  }

  /* Validation */
  function validateName() {
    if (!stateActionModify.name) return [];
    return validate(stateActionModify.name, [VALIDATORS.length('Action name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (JSON.stringify(stateActionModify) === JSON.stringify(actionParent)) { required.push('No changes detected') }
    if (stateAddedTriggerOptions.length == 0) { required.push('Action triggers are required') }
    if (stateAddedStepOptions.length == 0) { required.push('Action steps are required') }
    return [
      ...required,
      ...validateName(),
      ...stateValidateTriggers,
      ...stateValidateSteps,
    ].filter((error) => error.length > 0);
  }

  return (
    <div className='py-2'>
      <ModalDeleteMany
        isOpen={isOpenModalDeleteAction}
        toggle={toggleModalDeleteAction}
        type='actions'
        data={[{ uuid: actionParent.uuid, label: actionParent.name }]}
        onSubmit={async () => navigate(ROUTES.ROUTE_HOME_ACTIONS)}
      />
      <ModalActionUpdate isOpen={isOpenModalUpdateAction} toggle={toggleModalUpdateAction} action={stateActionModify} onSubmit={async () => { fetchActionParent(); }} />
      <div className='rounded-t-xl bg-base-300 p-2'>
        <div className='flex items-center'>
          <div className='font-semibold flex flex-grow'>Overview</div>
          <InputButton text='Save' before={<IconPencilSquareSolid className='h-5 mr-1' />} type='btn-primary' size='btn-sm' disabled={validateForm().length > 0} onClick={toggleModalUpdateAction} />
          <div className='w-2'></div>
          <InputButton text='Delete' before={<IconTrashSolid className='h-5 mr-1' />} type='btn-error' size='btn-sm' loading={false} onClick={toggleModalDeleteAction} />
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2 flex-grow'>
        <InputText className="w-60 mb-2 mr-4" labelText='Action name' value={stateActionModify.name} onChange={onChangeName} errors={validateName()} > </InputText>
        <div className="overflow-y-auto">
          <h3 className="font-bold text-sm mb-2">Triggers</h3>
          {stateAddedTriggerOptions.map((trigger, index) => {
            return (
              <div key={index + stateAddedTriggerOptions.length} className="mb-2 mr-4 bg-base-300 p-1 rounded-xl relative">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => removeTriggerOption(index)}>✕</button>
                <InputSelect className="w-48 mb-2 mr-4" errors={[]} labelText="Trigger Option" value={trigger.value} options={actionTriggerOptions} onChange={(event) => onChangeTriggerOption(index, event)}> </InputSelect>
                {trigger.value === ActionTriggerType.Scheduled && <ActionTriggerScheduleComponent
                  trigger={stateActionModify.triggers[index] as ActionTriggerSchedule}
                  setTrigger={(trigger) => onChangeTrigger(index, trigger)}
                  setValidateForm={(errors) => onChangeTriggerValidation(index, errors)} />}
              </div>
            )
          })}
          <div className="flex mb-2">
            <InputButton text='+ Add Trigger' type='btn-primary' onClick={addTriggerOption}></InputButton>
          </div>
          <h3 className="font-bold text-sm mb-2">Steps</h3>
          {stateAddedStepOptions.map((step, index) => {
            return (
              <div key={index + stateAddedStepOptions.length} className="mb-2 mr-4 bg-base-300 p-1 rounded-xl relative">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => removeStepOption(index)}>✕</button>
                <InputSelect className="w-48 mb-2 mr-4" errors={[]} labelText="Step Option" value={step.value} options={actionStepOptions} onChange={(event) => onChangeStepOption(index, event)}> </InputSelect>
                {step.value === ActionStepType.Email && <ActionStepEmailComponent
                  step={stateActionModify.steps[index] as ActionStepEmail}
                  setStep={(step) => onChangeStep(index, step)}
                  setValidateForm={(errors) => onChangeStepValidation(index, errors)} />}
              </div>
            )
          })}
          <div className="flex mb-2">
            <InputButton text='+ Add Step' type='btn-primary' onClick={addStepOption}></InputButton>
          </div>
        </div>
      </div>
    </div>
  )
};
