import { useContext, useEffect, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { StatusLoading } from "../../../components/status_loading";
import { IBeaconSelect } from "vigil-datamodel";
import { ContextOrganization } from "../../../providers/provider_organization";

interface ModalBeaconUpdateProps extends ModalProps {
  uuidBeacon: string;
  onSubmit?: () => Promise<void>;
}

export const ModalBeaconUpdate: React.FC<ModalBeaconUpdateProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);

  const [stateError, setError] = useState<string>('');
  const [stateLoading, setLoading] = useState(false);

  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  const [stateBeacon, setBeacon] = useState<IBeaconSelect>();
  const [stateNewBeacon, setNewBeacon] = useState<IBeaconSelect>();

  const [stateLocation, setLocation] = useState<GeolocationPosition>();

  /* Lifecycle */
  useEffect(() => { fetchBeacon() }, []);

  useEffect(() => {
    if (!stateLocation) return;
    if (!stateNewBeacon) return;
    setBeacon({
      ...stateNewBeacon,
      latitude: stateLocation.coords.latitude,
      longitude: stateLocation.coords.longitude,
      altitude: stateLocation.coords.altitude ?? 0,
    });
  }, [stateLocation]);

  /* Functions */
  function getLocation() {
    if (!navigator.geolocation) {
      setError('Geolocation is not supported by your browser');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation(position);
        setError('');
      },
      (error) => {
        setError(error.message);
      }
    );
  };

  /* DB Functions */
  async function fetchBeacon() {
    try {
      if (!organization.data) return;
      setLoading(true);
      const beacon = await vigil.functions.findOneOnOrganization({ type: 'beacons', uuidOrganization: organization.data.uuid, uuid: props.uuidBeacon });
      setBeacon(beacon);
      setNewBeacon(beacon);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function updateBeacon() {
    try {
      if (!organization.data) return;
      setError('');
      if (!stateNewBeacon) throw new Error('Beacon not found');
      await vigil.functions.updateOneOnOrganization({ type: 'beacons', uuidOrganization: organization.data?.uuid, uuid: props.uuidBeacon, data: stateNewBeacon });
      props.onSubmit && await props.onSubmit();
      props.toggle();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  /* Validation */
  function validateBeaconName() {
    if (!stateNewBeacon || !stateNewBeacon.name) return [];
    return validate(stateNewBeacon.name, [VALIDATORS.length('Beacon name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateNewBeacon || !stateNewBeacon.name) { required.push('Beacon name is required') }
    return [
      ...required,
      ...validateBeaconName(),
    ];
  }

  /* UI Updates */
  function onChangeBeaconName(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setNewBeacon(prevState => ({ ...prevState!, name: event.target.value }));
  }

  function disableSubmitButton() {
    if (!stateBeacon || !stateNewBeacon) return true;
    if (validateForm().length > 0) return true;
    if (JSON.stringify(stateBeacon) == JSON.stringify(stateNewBeacon)) return true;
    return false;
  }

  if (!stateBeacon || stateLoading || !stateNewBeacon) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <StatusLoading />
      </Modal>
    )
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Update Beacon</h3>
      {stateLocation && <p className="text-sm pb-4"><span className="font-bold">Location:</span> {stateLocation.coords.latitude}, {stateLocation.coords.longitude}, {stateLocation.coords.altitude}</p>}
      <InputButton text='Update Location' loading={false} disabled={false} type='btn-primary' onClick={() => getLocation()}></InputButton>
      <p className="text-sm pb-4"><span className="font-bold">QR code:</span> {stateBeacon.uuid}</p>
      <InputText labelText='Beacon name' value={stateNewBeacon.name} onChange={onChangeBeaconName} errors={validateBeaconName()} > </InputText>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={stateSubmitLoading} disabled={disableSubmitButton()} type='btn-primary' onClick={async () => await updateBeacon()}></InputButton>
      </div>
    </Modal>
  )
}
