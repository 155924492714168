import { ReactNode, createContext, useContext } from "react";
import { FullPageLoader } from "../components/full_page_loader";
import { ContextVigilClient } from "./provider_vigil_client";
import { IOrganizationRoleSelect, Permissions } from "vigil-datamodel";
import { ContextUser } from "./provider_user";
import { ContextOrganization } from "./provider_organization";
import { TTActions, TTResource } from "tt-permissions";
import { StatusAlert } from "../components/status_alert";
import { useCaller } from "../hooks/hook_caller";

class IRoleContext {
  organizationRoles: IOrganizationRoleSelect[] = [];
  userRoles: IOrganizationRoleSelect[] = [];

  constructor(organizationRoles: IOrganizationRoleSelect[], userRoles: IOrganizationRoleSelect[]) {
    this.organizationRoles = organizationRoles;
    this.userRoles = userRoles;
  }

  public hasUserPermission(actions: TTActions, resource: typeof TTResource) {
    return Permissions.hasUserPermission(this.userRoles, actions, resource);
  }
}

export const ContextRoles = createContext<IRoleContext>(new IRoleContext([], []));

interface ProviderRolesProps {
  children: ReactNode
}
export const ProviderRoles: React.FC<ProviderRolesProps> = (props: ProviderRolesProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);
  const user = useContext(ContextUser);
  const organization = useContext(ContextOrganization);

  /* State */
  const organizationRoles = useCaller({
    callback: async () => {
      if (!organization.data) return [];
      return vigil.functions.providerOrganizationRoles({ uuidOrganization: organization.data.uuid });
    },
    dependencies: { background: [organization.data], normal: [] }
  });

  const userRoles = useCaller({
    callback: async () => {
      if (!organization.data || !user.data) return [];
      return vigil.functions.providerUserRoles({ uuidOrganization: organization.data.uuid, uuidUser: user.data.uuid });
    },
    dependencies: { background: [organization.data, user.data], normal: [] }
  });

  if (organizationRoles.loading || userRoles.loading) return <FullPageLoader location={"roles"} />
  if (organizationRoles.error || userRoles.error) return <StatusAlert type="alert-error" message={organizationRoles.error || userRoles.error} />

  return (
    <ContextRoles.Provider value={{
      organizationRoles: organizationRoles.result!,
      userRoles: userRoles.result!,
      hasUserPermission: (actions: TTActions, resource: typeof TTResource) => Permissions.hasUserPermission(userRoles.result!, actions, resource)
    }}>
      {props.children}
    </ContextRoles.Provider>
  )
}
