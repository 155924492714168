import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { beacons } from "./bean_beacon";
import { relations } from "drizzle-orm";

export const linkOrganizationBeacons = sqliteTable('link_organization_beacons', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidBeacon: text('uuid_beacon').notNull().references(() => beacons.uuid),
});

export const linkOrganizationBeaconsRelations = relations(linkOrganizationBeacons, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkOrganizationBeacons.uuidOrganization],
    references: [organizations.uuid],
  }),
  beacon: one(beacons, {
    fields: [linkOrganizationBeacons.uuidBeacon],
    references: [beacons.uuid],
  }),
}));