import React, { useContext, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IDirectoryAction, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/hook_modal';
import { useNavigate } from 'react-router-dom';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { useTableData } from '../../hooks/hook_table_data';
import { ModalActionCreate } from './func_action/modal_action_create';
import { ModalDeleteMany } from '../../components/modal_delete_many';
import { ROUTES } from '../../router/routes';

interface ScreenHomeActionsProps { }

export const ScreenHomeActions: React.FC<ScreenHomeActionsProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const vigil = useContext(ContextVigilClient);

  const { isOpen: isOpenModalCreateAction, toggle: toggleModalCreateAction } = useModal();
  const { isOpen: isOpenModalDeleteAction, toggle: toggleModalDeleteAction } = useModal();

  const [selectedActionsState, setSelectedActions] = useState<IDirectoryAction[]>([]);

  const { data: actions, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryAction>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryActions({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateAction(selectedAction: IDirectoryAction) {
    navigate(ROUTES.ROUTE_HOME_ACTION.replace(':uuid', selectedAction.uuid))
  }

  function deleteActions(selectedActions: IDirectoryAction[]) {
    setSelectedActions(selectedActions)
    toggleModalDeleteAction();
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalActionCreate isOpen={isOpenModalCreateAction} toggle={toggleModalCreateAction} onSubmit={forceRefetch} />
      <ModalDeleteMany
        isOpen={isOpenModalDeleteAction}
        toggle={toggleModalDeleteAction}
        type='actions'
        data={selectedActionsState.map(action => ({ uuid: action.uuid, label: action.name }))}
        onSubmit={forceRefetch}
      />

      <Table<IDirectoryAction>
        className='h-full flex flex-col drop-shadow'
        name='Actions'
        data={actions}
        selectable={true}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'name',
            header: 'Name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateAction(item)}>
                  {item.name}
                </div>
              );
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'changeStamp',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('name', 'Name', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
          new DateFilter('changeStamp', 'Last Updated', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}

        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new action</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RAction),
            onClick: () => toggleModalCreateAction(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RAction),
            onClick: (selectedItems) => deleteActions(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RAction),
            onClick: (selectedItems) => deleteActions(selectedItems)
          }
        ]}
      />
    </div >
  );
};
