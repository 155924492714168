import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { IActionSelect } from 'vigil-datamodel';
import { ContextOrganization } from '../../providers/provider_organization';
import { ROUTES } from '../../router/routes';

interface ScreenHomeActionProps { }

export const ScreenHomeAction: React.FC<ScreenHomeActionProps> = (props) => {
  /* State */
  const navigate = useNavigate();
  const params = useParams();
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const uuidAction = params['uuid'] || '';

  const [stateError, setError] = useState<string>('');
  const [stateActionLoading, setActionLoading] = useState(true);
  const [stateAction, setAction] = useState<IActionSelect>();

  useEffect(() => { fetchAction(); }, [uuidAction]);

  async function fetchAction() {
    try {
      if (!organization.data) throw new Error('Organization not found');
      setActionLoading(true);
      const action = await vigil.functions.findOneOnOrganization({ type: 'actions', uuidOrganization: organization.data.uuid, uuid: uuidAction });
      setAction(action);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setActionLoading(false);
    }
  }

  function navigateTab(pageId: string) {
    navigate({
      pathname: ROUTES.ROUTE_HOME_ACTION.replace(':uuid', uuidAction) + '/' + pageId,
    })
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  if (stateActionLoading || !stateAction) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full h-full'>

      <Breadcrumbs crumbs={[{ id: 'actions', onClick: () => navigate(ROUTES.ROUTE_HOME_ACTIONS), text: 'Actions' }, { id: uuidAction, onClick: () => { }, text: stateAction.name }]} />

      {/* <Tabs selectedId={getSelectedId()} onSelect={navigateTab} items={[
        {
          icon: IconDocumentMagnifyingGlass,
          id: 'overview',
          text: 'Action Overview'
        },
      ]} /> */}

      <div className='w-full flex-grow'>
        <Outlet context={{ actionParent: stateAction, fetchActionParent: fetchAction }} />
      </div>
    </div>
  )
};
