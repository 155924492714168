import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { relations } from "drizzle-orm";

export function getReportPublicURI(baseUrl: string, uuidReport: string, startDate?: number, endDate?: number) {
  baseUrl = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
  if (!startDate || !endDate) {
    return `${baseUrl}public/report/${uuidReport}`;
  }
  return `${baseUrl}public/report/${uuidReport}?startDate=${startDate}&endDate=${endDate}`;
}

export function extractReportUuid(url: string) {
  const urlParams = new URLSearchParams(url.split('?')[1]);
  return urlParams.get('uuidReport') || '';
}

export enum ReportConfigType {
  Patrol = 'patrol',
}

export type BaseReportConfig = {
  type: ReportConfigType;
}

export type ReportConfigPatrol = BaseReportConfig & {
  type: ReportConfigType.Patrol,
  fromDurationHours: number,
  eventUuids: string[],
  deviceUuids: string[],
  siteUuids: string[],
}

export type ReportConfig = ReportConfigPatrol;

export const reports = sqliteTable('reports', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  name: text('name').notNull(),
  config: customJsonColumn<ReportConfig>('config').notNull(),
});

export const reportsRelations = relations(reports, ({ one }) => ({
  organization: one(organizations, {
    fields: [reports.uuidOrganization],
    references: [organizations.uuid],
  }),
}));