import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { linkOrganizationUsers } from "./bean_link_organization_user";
import { linkOrganizationRoleUser } from "./bean_link_organization_role_user";
import { organizationRoleUserInvite } from "./bean_organization_role_user_invite";
import { linkDeviceUserStatusses } from "./bean_link_device_user_status";

export const users = sqliteTable('users', {
  ...schemaBase(),
  firstName: text('first_name').notNull(),
  lastName: text('last_name').notNull(),
  idNumber: text('id_number').unique(),
  email: text('email').unique().notNull(),
  mobile: text('mobile').unique().notNull(),
});

export const usersRelations = relations(users, ({ many }) => ({
  usersToDevicesAndStatusses: many(linkDeviceUserStatusses),
  usersToOrganizations: many(linkOrganizationUsers),
  usersToOrganizationsAndRoles: many(linkOrganizationRoleUser),
  usersToOrganizationRoleInvites: many(organizationRoleUserInvite),
}));