import { useContext, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { ISiteInsert, ISiteSelect } from "vigil-datamodel";

interface ModalSiteCreateProps extends ModalProps {
  onSubmit?: (site: ISiteSelect) => Promise<void>;
}

export const ModalSiteCreate: React.FC<ModalSiteCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');

  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  const [stateSiteCreate, setSiteCreate] = useState<ISiteInsert>({ name: '' });

  /* DB Functions */
  async function createSite() {
    if (!organization.data) return;
    try {
      setError('');
      setSubmitLoading(true);
      const site = await vigil.functions.createOneOnOrganization({
        type: 'sites',
        uuidOrganization: organization.data.uuid,
        data: stateSiteCreate
      });
      props.onSubmit && await props.onSubmit(site);
      props.toggle();
      setSiteCreate({ name: '' })
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  /* Validation */
  function validateSiteName() {
    if (!stateSiteCreate.name) return [];
    return validate(stateSiteCreate.name, [VALIDATORS.length('Site name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateSiteCreate.name) { required.push('Site name is required') }
    return [
      ...required,
      ...validateSiteName(),
    ];
  }

  /* UI Updates */
  function onChangeSiteName(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setSiteCreate({ ...stateSiteCreate, name: event.target.value });
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Site</h3>
      <InputText labelText='Site name' value={stateSiteCreate.name} onChange={onChangeSiteName} errors={validateSiteName()} > </InputText>
      {stateError && <StatusAlert message={stateError} type={"alert-error"} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={stateSubmitLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await createSite()}></InputButton>
      </div>
    </Modal>
  )
}