import { integer, sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { relations } from "drizzle-orm";

// ActionTrigger
export enum ActionTriggerType {
  Scheduled = 'scheduled',
  Dispatch = 'dispatch',
}

export type BaseActionTrigger = {
  type: ActionTriggerType;
  enabled: boolean;
}

export type ActionTriggerSchedule = BaseActionTrigger & {
  type: ActionTriggerType.Scheduled;
  cron: string;
}

export type ActionTriggerDispatch = BaseActionTrigger & {
  type: ActionTriggerType.Dispatch;
}

export type ActionTrigger = ActionTriggerSchedule | ActionTriggerDispatch;

// ActionStep
export enum ActionStepType {
  Email = 'email',
  SMS = 'sms',
  Whatsapp = 'whatsapp',
}

export type BaseActionStep = {
  type: ActionStepType;
  enabled: boolean;
}

export interface ActionStepEmailButton {
  text: string;
  url: string;
}

export type ActionStepEmail = BaseActionStep & {
  type: ActionStepType.Email,
  toEmails: string[],
  subject: string,
  body: string,
  buttons: { text: string; url: string; }[],
  attachments: string[],
}

export type ActionStepSMS = BaseActionStep & {
  type: ActionStepType.SMS,
  toNumbers: string[],
  body: string,
}

export type ActionStepWhatsapp = BaseActionStep & {
  type: ActionStepType.Whatsapp,
  toNumbers: string[],
  body: string,
}

export type ActionStep = ActionStepEmail | ActionStepSMS | ActionStepWhatsapp;

export const actions = sqliteTable('actions', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  name: text('name').notNull(),
  triggers: customJsonColumn<ActionTrigger[]>('triggers').notNull(),
  steps: customJsonColumn<ActionStep[]>('steps').notNull(),
  enabled: integer('enabled').notNull().default(1),
  lastRunDate: integer('last_run_date', { mode: 'timestamp' }),
});

export const actionsRelations = relations(actions, ({ one }) => ({
  organization: one(organizations, {
    fields: [actions.uuidOrganization],
    references: [organizations.uuid],
  }),
}));