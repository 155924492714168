import React from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { IconBars4, IconMap } from '../../components/icons';
import { Tabs } from '../../components/tabs';

interface ScreenHomeOverviewProps { }

export const ScreenHomeOverview: React.FC<ScreenHomeOverviewProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  
  function navigateTab(id: string) {
    navigate(ROUTES.ROUTE_HOME_OVERVIEW + '/' + id)
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  return (
    <div className='p-4 w-full h-full flex flex-col'>
      <Tabs selectedId={getSelectedId()} onSelect={navigateTab} items={[
      {
        icon: IconMap,
        id: 'map',
        text: 'Map'
      },
      {
        icon: IconBars4,
        id: 'events',
        text: 'Active Events'
      },
      ]} />
      
      <div className='mt-2 h-full'>
        <Outlet />
      </div>

    </div>
  );
};
