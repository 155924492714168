import { useContext, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { IOrganizationRoleSelect, IOrganizationRoleInsert, Permissions } from "vigil-datamodel";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { TTPermissionSet } from "tt-permissions";
import { UIPermission, UiPermissions } from "./helper_role";

interface ModalRoleCreateProps extends ModalProps {
  onSubmit?: (role: IOrganizationRoleSelect) => Promise<void>;
}

export const ModalRoleCreate: React.FC<ModalRoleCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');

  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  const [stateRoleCreate, setRoleCreate] = useState<IOrganizationRoleInsert>({ name: '', uuidOrganization: '', permissionString: '', mutable: 1 });

  const [stateAllPermissionsSelected, setAllPermissionsSelected] = useState<boolean>(false);
  const [statePermissions, setPermissions] = useState<UIPermission[]>(UiPermissions);

  /* DB Functions */
  async function createRole() {
    if (!organization.data) return;
    try {
      setError('');
      setSubmitLoading(true);

      const permissionsSet = new TTPermissionSet(Permissions.schemaOrganization);
      for (const permission of statePermissions) {
        if (permission.parentPermission.selected) {
          permissionsSet.Grant(permission.parentPermission.resource, permission.parentPermission.action);
        }
        for (const childPermission of permission.childPermissions) {
          if (childPermission.selected) {
            permissionsSet.Grant(childPermission.resource, childPermission.action);
          }
        }
      }
      const permissionString = permissionsSet.ToString();

      const role = await vigil.functions.createOneOnOrganization({
        type: 'organizationRoles', uuidOrganization: organization.data.uuid, data: {
          ...stateRoleCreate,
          permissionString,
          uuidOrganization: organization.data.uuid,
          mutable: 1
        }
      });
      props.onSubmit && await props.onSubmit(role);
      props.toggle();
      resetState();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  /* Validation */
  function validateRoleName() {
    if (!stateRoleCreate.name) return [];
    return validate(stateRoleCreate.name, [VALIDATORS.length('Role name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateRoleCreate.name) { required.push('Role name is required') }
    return [
      ...required,
      ...validateRoleName(),
    ];
  }

  /* UI Updates */
  function onChangeRoleName(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setRoleCreate({ ...stateRoleCreate, name: event.target.value });
  }

  function toggleAllPermissions() {
    setAllPermissionsSelected(!stateAllPermissionsSelected);
    const permissions = statePermissions.map((permission) => {
      permission.parentPermission.selected = !stateAllPermissionsSelected;
      permission.childPermissions = permission.childPermissions.map((childPermission) => {
        childPermission.selected = !stateAllPermissionsSelected;
        return childPermission;
      });
      return permission;
    });
    setPermissions(permissions);
  }

  function toggleParentPermission(index: number) {
    statePermissions[index].parentPermission.selected = !statePermissions[index].parentPermission.selected;
    statePermissions[index].childPermissions = statePermissions[index].childPermissions.map((childPermission) => {
      childPermission.selected = statePermissions[index].parentPermission.selected;
      return childPermission;
    });
    setPermissions([...statePermissions]);
    if (!statePermissions.every(permission => permission.parentPermission.selected)) {
      setAllPermissionsSelected(false);
    }
    if (statePermissions.every(permission => permission.parentPermission.selected)) {
      setAllPermissionsSelected(true);
    }
  }

  function toggleChildPermission(indexParent: number, indexChild: number) {
    statePermissions[indexParent].childPermissions[indexChild].selected = !statePermissions[indexParent].childPermissions[indexChild].selected;
    statePermissions[indexParent].parentPermission.selected = statePermissions[indexParent].childPermissions.every((childPermission) => childPermission.selected);
    setPermissions([...statePermissions]);
    if (!statePermissions.every(permission => permission.parentPermission.selected)) {
      setAllPermissionsSelected(false);
    }
    if (statePermissions.every(permission => permission.parentPermission.selected)) {
      setAllPermissionsSelected(true);
    }
  }

  function resetState() {
    setRoleCreate({ name: '', uuidOrganization: '', permissionString: '', mutable: 1 });
    setAllPermissionsSelected(false);
    statePermissions.map((permission) => {
      permission.parentPermission.selected = false;
      permission.childPermissions = permission.childPermissions.map((childPermission) => {
        childPermission.selected = false;
        return childPermission;
      });
      return permission;
    });
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-192">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Role</h3>
      <InputText className="w-60 mb-2" labelText='Role name' value={stateRoleCreate.name} onChange={onChangeRoleName} errors={validateRoleName()} > </InputText>
      <label className="p-0 mb-2 label cursor-pointer max-w-fit">
        <input type="checkbox" checked={stateAllPermissionsSelected} className="checkbox" onChange={toggleAllPermissions} />
        <span className="label-text font-bold pl-2">All permissions</span>
      </label>
      <div className="overflow-y-auto">
        {statePermissions.map((permission, indexP) => {
          return (
            <div key={indexP} className="mb-2">
              <label className="p-0 pb-1 label cursor-pointer max-w-fit">
                <input type="checkbox" checked={permission.parentPermission.selected} className="checkbox" onChange={() => toggleParentPermission(indexP)} />
                <span className="label-text font-semibold pl-2">{permission.parentPermission.name}</span>
              </label>
              <div className="flex flex-wrap">
                {permission.childPermissions.map((childPermission, indexC) => {
                  return (
                    <label key={indexC} className="p-0 mb-2 mr-4 label cursor-pointer max-w-fit">
                      <input type="checkbox" checked={childPermission.selected} className="checkbox" onChange={() => toggleChildPermission(indexP, indexC)} />
                      <span className="label-text pl-2">{childPermission.name}</span>
                    </label>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      {stateError && <StatusAlert message={stateError} type={"alert-error"} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={stateSubmitLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await createRole()}></InputButton>
      </div>
    </Modal>
  )
}