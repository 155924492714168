import { useState } from "react";
import { ModalProps } from "../../../components/modal";
import { IOrganizationRoleSelect } from "vigil-datamodel";
import { InputSelect } from "../../../components/input_select";
import { VQROrganizationInviteUser, VigilQRCodes } from "vigil-qr";
import { VigilQRDisplay } from "../../../components/qr_display";

interface UserInviteCreateQrProps extends ModalProps {
  organizationRoles: IOrganizationRoleSelect[];
  lowestRole: IOrganizationRoleSelect;
}

export const UserInviteCreateQr: React.FC<UserInviteCreateQrProps> = (props) => {
  const [stateSelectedRole, setSelectedRole] = useState<IOrganizationRoleSelect>(props.lowestRole);
  const [stateQR, setQR] = useState<VQROrganizationInviteUser>({
    uuidOrganization: props.lowestRole.uuidOrganization,
    uuidRole: props.lowestRole.uuid
  });

  /* UI Updates */
  function onChangeSelectedRole(event: React.ChangeEvent<HTMLSelectElement>) {
    const role = props.organizationRoles.find((role) => role.uuid == event.target.value);
    if (role) {
      setSelectedRole(role);
      setQR({ ...stateQR, uuidRole: role.uuid })
    }
  }

  return (
    <div className="pt-2">
      <div className="flex justify-center">
        <div className='w-64'> <VigilQRDisplay qrcode={() => VigilQRCodes.organizationInviteUser.toSVGDataURL(stateQR)} /> </div>
      </div>
      <InputSelect errors={[]} labelText="Role" value={stateSelectedRole.uuid} options={props.organizationRoles.map((role) => ({ label: role.name, value: role.uuid }))} onChange={onChangeSelectedRole}> </InputSelect>
    </div>
  )
}