import { VigilClient } from "vigil-client";

function getStartsWith(siteName: string) {
  const upperSiteName = siteName.toUpperCase();
  const nameLength = upperSiteName.length;

  if (nameLength < 4) {
    return upperSiteName + 'X'.repeat(4 - nameLength);
  } else if (nameLength > 4) {
    return upperSiteName[0] + upperSiteName[1] + upperSiteName[Math.ceil(nameLength / 2)] + upperSiteName[nameLength - 1];
  }
  return upperSiteName;
}

export async function getBeaconDefaultName(vigil: VigilClient, uuidOrganization: string, params: ({ uuidSite: string, startWith?: never } | { uuidSite?: never, startWith: string })) {
  let startWith = '';

  if (params.uuidSite) {
    const site = await vigil.functions.findOneOnOrganization({ type: 'sites', uuidOrganization, uuid: params.uuidSite });
    if (!site) throw new Error('Site not found');
    startWith = getStartsWith(site.name).toUpperCase();
  } else if (params.startWith) {
    startWith = params.startWith.toUpperCase();
  }

  const beacons = await vigil.functions.findManyLinkedToOrganization({ type: 'beacons', uuidOrganization, filters: [{ id: 'name', query: 'startsWith', value: startWith }] });

  const largestNum = Math.max(...beacons.map(beacon => {
    const split = beacon.name.split('-');
    const nameNum = parseInt(split[split.length - 1]);
    if (isNaN(nameNum)) return -1;
    return nameNum;
  }));

  if (Infinity === largestNum || -Infinity === largestNum) return `${startWith}-0`;

  return `${startWith}-${largestNum + 1}`;
}
