import { useContext, useState } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { IActionSelect } from "vigil-datamodel";
import { ContextOrganization } from "../../../providers/provider_organization";

interface ModalActionUpdateProps extends ModalProps {
  action: IActionSelect;
  onSubmit?: () => Promise<void>;
}

export const ModalActionUpdate: React.FC<ModalActionUpdateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  /* DB Functions */
  async function updateAction() {
    try {
      if (!organization.data) throw new Error('Organization not found');
      setError('');
      setSubmitLoading(true);
      await vigil.functions.updateOneOnOrganization({
        type: 'actions',
        uuidOrganization: organization.data.uuid,
        uuid: props.action.uuid,
        data: props.action
      });
      props.onSubmit && await props.onSubmit();
      props.toggle();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-128">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Save Action</h3>
      <div>Are you sure you want to save your changes?</div>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Save' loading={stateSubmitLoading} disabled={false} type='btn-primary' onClick={async () => { await updateAction() }}></InputButton>
      </div>
    </Modal>
  )
}