import { IUserSelect, IOrganizationRoleSelect, IDeviceSelect } from "vigil-datamodel";
import { IPatrolMember } from "vigil-datamodel";
import { DurationState } from "../../../components/input_duration";
import { TimeState } from "../../../components/input_time";

export interface IPatrolMemberInfo extends IPatrolMember {
  info: IUserSelect | IOrganizationRoleSelect | IDeviceSelect;
}

export interface PatrolOccurance {
  MON: boolean;
  TUE: boolean;
  WED: boolean;
  THU: boolean;
  FRI: boolean;
  SAT: boolean;
  SUN: boolean;
}

function getTimeZoneOffset() {
  const now = new Date();
  return now.getTimezoneOffset() / 60;
}

export function getPatrolCronExpression(timeInstance: TimeState, occuranceInstance: PatrolOccurance) {
  const offset = getTimeZoneOffset();
  const tempHour = timeInstance.hour + offset;
  const days: number[] = [];
  if (occuranceInstance.MON) days.push(1);
  if (occuranceInstance.TUE) days.push(2);
  if (occuranceInstance.WED) days.push(3);
  if (occuranceInstance.THU) days.push(4);
  if (occuranceInstance.FRI) days.push(5);
  if (occuranceInstance.SAT) days.push(6);
  if (occuranceInstance.SUN) days.push(0);
  const hour = timeInstance.period == 'PM' ? tempHour + 12 : tempHour;
  return `${timeInstance.minute} ${hour} * * ${days.join(',')}`;
}

export function getPatrolDuration(durationInstance: DurationState) {
  const hours = durationInstance.hours ?? 0;
  const minutes = durationInstance.minutes ?? 0;
  const seconds = durationInstance.seconds ?? 0;
  return hours * 3600 + minutes * 60 + seconds;
}

export function getPatrolTimeState(cronExpression: string) {
  const offset = getTimeZoneOffset();
  const [minuteStr, hourStr] = cronExpression.split(' ').slice(0, 2);
  const hour = parseInt(hourStr) - offset;
  const minute = parseInt(minuteStr);
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour > 12 ? hour - 12 : hour;
  return {
    minute,
    hour: hour12,
    period: period as 'AM' | 'PM',
  };
}

export function getPatrolOccurance(cronExpression: string) {
  const [, , , , daysStr] = cronExpression.split(' ');
  const days = daysStr.split(',').map(day => parseInt(day));
  return {
    MON: days.includes(1),
    TUE: days.includes(2),
    WED: days.includes(3),
    THU: days.includes(4),
    FRI: days.includes(5),
    SAT: days.includes(6),
    SUN: days.includes(0),
  };
}

export function getPatrolDurationState(duration: number) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = duration - hours * 3600 - minutes * 60;
  return {
    hours,
    minutes,
    seconds,
  };
}
