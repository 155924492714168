import { events, IEvent } from "./bean_event";
import { integer, sqliteTable,  text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { eventInstanceLogs } from "./bean_event_instance_log";

export const eventInstances = sqliteTable('event_instances', {
  ...schemaBase(),
  dateTimeStart: integer('date_time_start', { mode: 'timestamp' }).notNull(),
  dateTimeEnd: integer('date_time_end', { mode: 'timestamp' }).notNull(),
  uuidEvent: text('uuid_event').notNull().references(() => events.uuid),
  eventSnapshot: customJsonColumn<IEvent>('event').notNull(),
});

export const eventInstancesRelations = relations(eventInstances, ({ one, many }) => ({
  eventInstanceLogs: many(eventInstanceLogs),
  event: one(events, {
    fields: [eventInstances.uuidEvent],
    references: [events.uuid],
  }),
}));