import { INotificationPanic } from "./bean_notification";
import { ICreator } from "./type_creator";
import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { eventInstances } from "./bean_event_instance";
import { relations } from "drizzle-orm";

export enum EventInstanceLogType {
  Panic = 'panic',
  Generic = 'generic',
  Beacon = 'beacon',
}

interface IEventInstanceLogBase { uuidEventLogParent?: string }
export type IEventInstanceLogPanic = IEventInstanceLogBase & INotificationPanic
export type IEventInstanceLogGeneric = IEventInstanceLogBase & {
  tags: string[],
  data: Record<string, any>,
}

export interface IEventInstanceLogBeacon {
  uuidEventLogParent?: string;
  beaconUuid: string;
  beaconName: string;
  beaconLat: number;
  beaconLng: number;
  beaconAlt: number;
  userUuid: string;
  userLat: number;
  userLng: number;
  userAlt: number;
  userAccuracy: number;
}

export type EventInstanceLogTypeData = (
  | { type: EventInstanceLogType.Panic, data: IEventInstanceLogPanic }
  | { type: EventInstanceLogType.Generic, data: IEventInstanceLogGeneric }
  | { type: EventInstanceLogType.Beacon, data: IEventInstanceLogBeacon }
)

export const eventInstanceLogs = sqliteTable('event_instance_logs', {
  ...schemaBase(),
  uuidEventInstance: text('uuid_event_instance').notNull().references(() => eventInstances.uuid),
  creator: customJsonColumn<ICreator>('creator').notNull(),
  type: text('type', { enum: Object.values(EventInstanceLogType) as [EventInstanceLogType] }).notNull(),
  data: customJsonColumn<EventInstanceLogTypeData>('data').notNull(),
});

export const eventInstanceLogsRelations = relations(eventInstanceLogs, ({ one }) => ({
  eventInstance: one(eventInstances, {
    fields: [eventInstanceLogs.uuidEventInstance],
    references: [eventInstances.uuid],
  }),
}));
