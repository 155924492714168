import { StatusLoading } from "./status_loading";

export const FullPageLoader: React.FC<{ location?: string }> = (props: { location?: string }) => {
  return (
    <div className="flex w-full h-full items-center justify-center">
      {"Source: " + props.location}
      <StatusLoading />
    </div>
  )
}
