import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { notifications } from "./bean_notification";
import { users } from "./bean_user";
import { relations } from "drizzle-orm";

export enum NotificationLogStatus {
  Received = 'received',
  Acknowledged = 'acknowledged',
  Expired = 'expired',
  Dismissed = 'dismissed',
}

export const notificationLogs = sqliteTable('notification_logs', {
  ...schemaBase(),
  uuidNotification: text('uuid_notification').notNull().references(() => notifications.uuid),
  uuidUser: text('uuid_user').notNull().references(() => users.uuid),
  type: text('type', { enum: Object.values(NotificationLogStatus) as [NotificationLogStatus] }).notNull(),
});

export const notificationLogsRelations = relations(notificationLogs, ({ one }) => ({
  notification: one(notifications, {
    fields: [notificationLogs.uuidNotification],
    references: [notifications.uuid],
  }),
  user: one(users, {
    fields: [notificationLogs.uuidUser],
    references: [users.uuid],
  }),
}));