import { TTFunction } from 'tt-coms';
import { IActionSelect, IBeaconSelect, IDashActivityLogInsert, IDashStateLogInsert, IDeviceActivityLogSelect, IDeviceSelect, IDeviceStateLogSelect, IEventInstanceLogSelect, IEventInstanceSelect, IEventSelect, ILinkOrganizationUserSelect, INotificationLogSelect, INotificationSelect, IOrganizationRoleSelect, IOrganizationRoleUserInviteSelect, IOrganizationSelect, IReportSelect, ISiteSelect, IUserSelect } from './beans_type';
import { createFindManyBeacon, createFindManyNotification, createFindManyOrganization, createFindManyOrganizationRole, createFindManySite, createFindManyUnlinkedFromBeacon, createFindManyUnlinkedFromSite, createFindManyUser } from './type_find_many';
import { createFindOneOnOrganization } from './type_find_one';
import { QueryFilter } from './query_filter';
import { createCreateOneOnOrganization } from './type_create_one';
import { createUpdateOneOnOrganization } from './type_update_one';
import { createCreateLink } from './type_create_link';
import { createDeleteManyOnOrganization } from './type_delete_many';
import { createDeleteLink } from './type_delete_link';

// Query types
export interface IPagination {
  page: number,
  count: number
}

export type SortOrder = 'ascending' | 'descending';

export interface IOrdering<T> {
  field: keyof T,
  direction: SortOrder
}

export interface IPaginatedResponse<T> {
  items: T[],
  pagination: {
    pages: number,
    count: number
  }
}

export type IDirectoryRole = IOrganizationRoleSelect & { userCount: number };
export type IDirectoryUser = IUserSelect & { roleCount: number };
export type IDirectoryUserInvite = IOrganizationRoleUserInviteSelect & { inviteType: string, inviteeContact: string };
export type IDirectorySite = ISiteSelect & { beaconCount: number };
export type IDirectoryBeacon = IBeaconSelect & { siteCount: number };
export type IDirectoryDevice = IDeviceSelect;
export type IDirectoryEvent = IEventSelect;
export type IDirectoryEventInstance = IEventInstanceSelect;
export type IDirectoryReport = IReportSelect;
export type IDirectoryAction = IActionSelect;
export type IDirectoryNotification = INotificationSelect & { userReceiveCount: number, userDismissedCount: number };
export type IDirectoryDeviceActivityLog = IDeviceActivityLogSelect & { user?: IUserSelect };

export type IPublicReport = IReportSelect & { events: (IEventSelect & { eventInstanceLogs: IEventInstanceLogSelect[] })[], users: IUserSelect[], sites: (ISiteSelect & { beacons: IBeaconSelect[] })[], devices: IDeviceSelect[], deviceActivityLogs: IDeviceActivityLogSelect[], deviceStateLogs: IDeviceStateLogSelect[] }

export type IProfileResult = {
  organizations: IOrganizationSelect[],
  organizationInvites: (IOrganizationRoleUserInviteSelect & { organization: IOrganizationSelect })[];
}

export type DeviceHistoryItem = {
  date: Date,
  chargeLevel: number,
  chargeState: "Charging" | "Discharging",
  lat: number,
  lng: number,
  acc: number,
}

export enum TimeFrame {
  Minute15 = '15m',
  Hour1 = '1h',
  Hour6 = '6h',
  Day1 = '1d',
  Day3 = '3d',
  Week1 = '1w',
  Week2 = '2w',
}

export type IDeviceUserPair = {
  device: IDeviceSelect,
  deviceStateLog: IDeviceStateLogSelect,
  user?: IUserSelect
}

export type IMapStateOrganization = {
  beacons?: IBeaconSelect[]
  devices?: IDeviceUserPair[]
}

export type IMapState = {
  organizations: IMapStateOrganization[]
}

export interface VigilContextClient {
  token: string,
}

function directoryFunction<T>() {
  return TTFunction<{ uuidOrganization: string, filters: QueryFilter<T>[], pagination: IPagination, ordering: IOrdering<T> }, IPaginatedResponse<T>>();
}

export class VigilFunctionsSchema {
  ping = TTFunction<{}, string>();

  // App Web Provider Functions
  providerOrganizationRoles = TTFunction<{ uuidOrganization: string }, IOrganizationRoleSelect[]>();
  providerUserRoles = TTFunction<{ uuidOrganization: string, uuidUser: string }, IOrganizationRoleSelect[]>();

  // Create Functions
  createOneOnOrganization = createCreateOneOnOrganization();
  createLink = createCreateLink();

  // Query Functions
  findOneOnOrganization = createFindOneOnOrganization();
  findManyLinkedToOrganization = createFindManyOrganization();
  findManyLinkedToSite = createFindManySite();
  findManyUnlinkedFromSite = createFindManyUnlinkedFromSite();
  findManyLinkedToBeacon = createFindManyBeacon();
  findManyUnlinkedFromBeacon = createFindManyUnlinkedFromBeacon();
  findManyLinkedToUser = createFindManyUser();
  findManyLinkedToOrganizationRole = createFindManyOrganizationRole();
  findManyNotification = createFindManyNotification();

  // Delete Functions
  deleteManyOnOrganization = createDeleteManyOnOrganization();
  deleteLink = createDeleteLink();

  // Update Functions
  updateOneOnOrganization = createUpdateOneOnOrganization();
  updateMyUser = TTFunction<{ firstName: string, lastName: string }, IUserSelect>();

  // Sign In/Up Functions
  userSignInRequest = TTFunction<{ target: { email?: string; mobile?: string } }, void>();
  userSignInConfirm = TTFunction<{ target: string, value: string }, { token: string, result: IUserSelect & { usersToOrganizations: (ILinkOrganizationUserSelect & { organization: IOrganizationSelect })[] } }>();
  userMobileRequest = TTFunction<{ mobile: string }, void>();
  userMobileConfirm = TTFunction<{ mobile: string; otp: string }, string>();
  userEmailRequest = TTFunction<{ email: string }, void>();
  userEmailConfirm = TTFunction<{ email: string; otp: string }, string>();
  userNameUpdate = TTFunction<{ firstName: string, lastName: string }, string>();

  // Dash Functions
  dashSessionUpsert = TTFunction<{ uuid: string, browserType: string, operatingSystem: string }, void>();
  dashStateLogCreate = TTFunction<{ info: IDashStateLogInsert }, void>();
  dashActivityLogCreate = TTFunction<{ info: IDashActivityLogInsert }, void>();

  // Organization Functions
  organizationFindOne = TTFunction<{ uuid: string }, IOrganizationSelect>();
  organizationCreateOne = TTFunction<{ name: string }, IOrganizationSelect>();

  // Notification Functions
  notificationsUnseen = TTFunction<{ uuidOrganization: string, uuidUser: string, createdAfter: Date }, (INotificationSelect & { notificationLogs: INotificationLogSelect[] })[]>();
  notificationsMarkReceived = TTFunction<{ uuidNotification: string, uuidUser: string }, void>();
  notificationsMarkDismissed = TTFunction<{ uuidNotification: string, uuidUser: string }, void>();

  // Profile Functions
  userProfile = TTFunction<{}, IProfileResult>();
  userOrganizationRoleInviteAccept = TTFunction<{ uuidUserOrganizationRoleInvite: string }, void>();
  userOrganizationRoleInviteReject = TTFunction<{ uuidUserOrganizationRoleInvite: string }, void>();

  // Overview - Map
  overviewMap = TTFunction<{ filter: { uuidsOrganizations: string[], beacons?: boolean, devices?: boolean, } }, IMapState>();

  // Directories
  directorySites = directoryFunction<IDirectorySite>();
  directoryBeacons = directoryFunction<IDirectoryBeacon>();
  directoryDevices = directoryFunction<IDirectoryDevice>();
  directoryUsers = directoryFunction<IDirectoryUser>();
  directoryUserInvites = directoryFunction<IDirectoryUserInvite>();
  directoryRoles = directoryFunction<IDirectoryRole>();
  directoryEvents = directoryFunction<IDirectoryEvent>();
  directoryEventInstances = directoryFunction<IDirectoryEventInstance>();
  directoryReports = directoryFunction<IDirectoryReport>();
  directoryActions = directoryFunction<IDirectoryAction>();
  directoryNotifications = directoryFunction<IDirectoryNotification>();

  // Logs
  eventInstanceLogs = TTFunction<{ uuidEvent?: string, filters: QueryFilter<IEventInstanceLogSelect>[], pagination: IPagination, ordering: IOrdering<IEventInstanceLogSelect> }, IPaginatedResponse<IEventInstanceLogSelect>>()
  deviceStateLogs = TTFunction<{ filters: QueryFilter<IDeviceStateLogSelect>[], pagination: IPagination, ordering: IOrdering<IDeviceStateLogSelect> }, IPaginatedResponse<IDeviceStateLogSelect>>()
  deviceActivityLogs = TTFunction<{ filters: QueryFilter<IDirectoryDeviceActivityLog>[], pagination: IPagination, ordering: IOrdering<IDirectoryDeviceActivityLog> }, IPaginatedResponse<IDirectoryDeviceActivityLog>>()
  deviceHistory = TTFunction<{ uuidOrganization: string, uuidDevice: string, timeframe: TimeFrame }, { items: DeviceHistoryItem[] }>();

  // Public
  publicReport = TTFunction<{ uuidReport: string, startDate: Date, endDate: Date }, IPublicReport>();

  // Device Functions
  deviceRegistration = TTFunction<{ serialNumber: string, imei: string }, IDeviceSelect>();
}

export const VigilFunctions = new VigilFunctionsSchema();