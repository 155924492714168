import React, { useContext, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IDirectoryUserInvite, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/hook_modal';
import { ModalUserInviteDelete } from './func_user_invite/modal_user_invite_delete';
import { TTActions } from 'tt-permissions';
import { ContextRoles } from '../../providers/provider_roles';
import { useTableData } from '../../hooks/hook_table_data';

interface TabUsersInviteProps { }

export const TabUsersInvite: React.FC<TabUsersInviteProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);

  const { isOpen: isOpenModalUserInviteDelete, toggle: toggleModalUserInviteDelete } = useModal();

  const [selectedUserInvitesState, setUserSelectedInvites] = useState<IDirectoryUserInvite[]>([]);

  const { data: userInvites, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryUserInvite>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryUserInvites({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function deleteUserInvites(selectedUserInvites: IDirectoryUserInvite[]) {
    setUserSelectedInvites(selectedUserInvites)
    toggleModalUserInviteDelete();
  }

  return (
    <div className='pt-2 h-full w-full'>
      <ModalUserInviteDelete isOpen={isOpenModalUserInviteDelete} toggle={toggleModalUserInviteDelete} onSubmit={forceRefetch} userInvites={selectedUserInvitesState} />

      <Table<IDirectoryUserInvite>
        className='h-full flex flex-col drop-shadow'
        name='Invites'
        data={userInvites}
        selectable={true}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'inviteeContact',
            header: 'Invitee Contact',
            sortable: true,
            value(item) {
              return <div>{item.inviteeContact}</div>;
            },
          },
          {
            id: 'inviteType',
            header: 'Invite Type',
            sortable: true,
            value(item) {
              return <div>{item.inviteType}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'changeStamp',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={[
          // TODO: If we add this filter, we need to change backend function as well
          // new TextFilter('invitee_contact', 'Invitee Contact', 'contains'),
          new DateFilter('date_created', 'Date Created', 'between'),
          new DateFilter('last_updated', 'Last Updated', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RUserInvite),
            onClick: (selectedItems) => deleteUserInvites(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RUserInvite),
            onClick: (selectedItems) => deleteUserInvites(selectedItems)
          }
        ]}
      />
    </div >
  );
};
