import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from '../../components/table/table';
import { IEventInstanceLogSelect } from 'vigil-datamodel';
import { TTuuid } from 'tt-uuid';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { useTableData } from '../../hooks/hook_table_data';

interface ScreenHomeEventInstanceLogsProps { }

export const ScreenHomeEventInstanceLogs: React.FC<ScreenHomeEventInstanceLogsProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidEventInstance = params['uuid'] || '';

  const { data, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange } = useTableData<IEventInstanceLogSelect>({
    fetchData: async ({ filters, sorting, paging }) => {
      const response = await vigil.functions.eventInstanceLogs({
        filters: [
          { id: 'uuidEventInstance', query: 'equals', value: uuidEventInstance },
          ...filters,
        ],
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [uuidEventInstance]
  });

  return (
    <div className='flex flex-col h-full'>
      <Table<IEventInstanceLogSelect>
        className='h-full flex flex-col drop-shadow my-2'
        name='Event Instance Logs'
        data={data}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'uuid',
            header: 'Unique ID',
            sortable: true,
            value(item) {
              return <div>{item.uuid}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'changeStamp',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'type',
            header: 'Type',
            sortable: true,
            value(item) {
              return <div>{item.type}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('uuid', 'Unique ID', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
          new DateFilter('changeStamp', 'Last Updated', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div>
  );
};
