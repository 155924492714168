import { text, integer, blob } from 'drizzle-orm/sqlite-core';
import { TTuuid } from 'tt-uuid';

export const schemaBase = () => { return {
  uuid: text('uuid').primaryKey().$default(() => TTuuid.getCuuid()),
  changeStamp: text('changestamp').notNull().$onUpdate(() => TTuuid.getCuuid()), // TODO: Removed unique, because if we do a multi update, the changestamp will be the same
  serverStamp: text('serverstamp').notNull().$default(() => TTuuid.getCuuid()), // TODO: Removed unique, because if we do a multi update, the serverstamp will be the same
  deleted: integer('deleted').notNull().default(0),
}};

export const customJsonColumn = <T>(columnName: string) => blob(columnName, { mode: 'json' }).$type<T>();
