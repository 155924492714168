import { useState, useRef } from "react";
import { IconXCircleSolid, IconPlusSolid } from "../icons";
import { DateFilter, TableDateFilterModal } from "./table_filters/table_filter_date";
import { TextFilter, TableTextFilterModal } from "./table_filters/table_filter_text";
import { QueryFilter, BaseFilter, isTextQuery, filterQueries, isDateQuery } from "./table_filters/table_filter_types";

interface TableFilterBarProps<T> {
  filtersOptions: BaseFilter[];
  filtersSelected: QueryFilter<T>[];
  onFiltersChange: (filters: QueryFilter<T>[]) => void;
}

export const TableFilterBar = <T,>(props: TableFilterBarProps<T>) => {
  const [filterFocus, setFilterFocus] = useState(false);
  const filterInputRef = useRef<HTMLInputElement | null>(null);
  const [filterInputValue, setFilterInputValue] = useState('');
  const [isOpenFilterListDropdown, setIsOpenFilterListDropdown] = useState(false);
  const [isOpenFilterDropdown, setIsOpenFilterDropdown] = useState(false);
  const [filterRender, setFilterRender] = useState<JSX.Element | null>(null);

  function focusFilter() {
    if (!filterInputRef.current) {
      return;
    }
    setFilterFocus(true);
    setIsOpenFilterListDropdown(true);
    filterInputRef.current.focus();
  }

  function unFocusFilter() {
    setFilterFocus(false);
  }

  function toggleFilterListDropdown() {
    setIsOpenFilterListDropdown(!isOpenFilterListDropdown);
  }

  function toggleFilterDropdown() {
    setIsOpenFilterDropdown(!isOpenFilterDropdown);
  }

  function updateFilterState(filter: QueryFilter<T>) {
    if (!props.filtersSelected) return;
    const newFilterState = [...props.filtersSelected];
    const index = newFilterState.findIndex(f => f.id === filter.id);
    if (index === -1) {
      newFilterState.push(filter);
    } else {
      newFilterState[index] = filter;
    }
    props.onFiltersChange(newFilterState);
  }

  function clearFilterState(filter: QueryFilter<T>) {
    if (!props.filtersSelected) return;
    const newFilterState = [...props.filtersSelected];
    const index = newFilterState.findIndex(f => f.id === filter.id);
    if (index !== -1) {
      newFilterState.splice(index, 1);
    }
    props.onFiltersChange(newFilterState);
  }

  function selectFilterType(filter: BaseFilter) {
    toggleFilterListDropdown();
    toggleFilterDropdown();
    if (filter instanceof TextFilter) {
      setFilterRender(<TableTextFilterModal textFilter={filter} onClose={() => { setIsOpenFilterDropdown(false) }} onSubmit={(queryFilter) => { updateFilterState(queryFilter); setIsOpenFilterDropdown(false); setFilterInputValue('') }} />);
    }
    if (filter instanceof DateFilter) {
      setFilterRender(<TableDateFilterModal dateFilter={filter} onClose={() => { setIsOpenFilterDropdown(false) }} onSubmit={(queryFilter) => { updateFilterState(queryFilter); setIsOpenFilterDropdown(false) }} />);
    }
    // TODO: Add more filter types
  }

  // To show or hide UI components
  function dropdownFilterListShow() {
    if (!props.filtersSelected) return;
    const notAllFiltersSelected = props.filtersOptions.length !== props.filtersSelected!.length;
    const filtersLeftText = props.filtersOptions.find(f => (f instanceof TextFilter) && !props.filtersSelected.find(fs => fs.id === f.id));
    if (filterInputValue !== '') {
      return notAllFiltersSelected && filtersLeftText;
    }
    return notAllFiltersSelected;
  }

  return (
    <div className='bg-base-100 h-12 p-2 flex space-x-2'>

      {/* Filters that is applied */}
      {props.filtersSelected.map((filter, index) => {
        const filterType = props.filtersOptions.find(f => f.id === filter.id);
        const filterName = props.filtersOptions.find(f => f.id === filter.id)?.name;
        let filterValue = '';
        let filterQuery = '';
        if (filterType instanceof TextFilter && isTextQuery(filter.query)) {
          filterQuery = filterQueries.textQueries[filter.query].toLowerCase();
          filterValue = `"${filter.value}"`;
        }
        if (filterType instanceof DateFilter && isDateQuery(filter.query)) {
          filterQuery = filterQueries.dateQueries[filter.query].toLowerCase();
          if (filter.value instanceof Date) {
            filterValue = `${filter.value.toLocaleDateString()}`;
          } else {
            filterValue = `${(filter.value.valueOf() as any)['startDate']} - ${(filter.value.valueOf() as any)['endDate']}`;
          }
        }
        // TODO: Add more filter types

        return (
          <div className='min-w-max' key={filter.id as any}>
            <div key={index} className='flex items-center justify-center bg-primary-content rounded-3xl h-8 text-sm px-4'>
              <div>{filterName} {filterQuery}: {filterValue}</div>
              <button onClick={() => { clearFilterState(filter) }}>
                <IconXCircleSolid className='ml-2' style={{ height: '18px' }} />
              </button>
            </div>
          </div>
        )
      })}

      {/* Filter input */}
      <div className="dropdown w-full">
        {isOpenFilterDropdown && (
          <div className='absolute z-[1] card card-compact p-2 drop-shadow-xl bg-base-100'>
            {filterRender}
          </div>
        )}
        <div className='h-10'>
          {!isOpenFilterDropdown && props.filtersOptions.length !== props.filtersSelected.length &&
            <div onClick={focusFilter} className='flex cursor-text space-x-1'>
              {!filterFocus && filterInputValue === '' && (
                <div className='min-w-max'>
                  <div className='flex items-center justify-center border border-dashed rounded-3xl h-8 text-sm px-4'>
                    <IconPlusSolid className='h-4' />
                    <div>Add a filter</div>
                  </div>
                </div>
              )}
              <input ref={filterInputRef} type="text" className="input input-md w-full h-8" onFocus={focusFilter} onBlur={unFocusFilter} value={filterInputValue} onChange={(event) => { setFilterInputValue(event.target.value) }} />
            </div>
          }
        </div>
        {/* This is to filter whenever there is no text filters left */}
        {dropdownFilterListShow() && (
          <ul className={`dropdown-content menu drop-shadow-xl bg-base-100 rounded-box z-10 mt-1 outline outline-1 outline-neutral-content ${isOpenFilterListDropdown ? 'visible' : 'hidden'}`} style={{ minWidth: 180 }}>
            {props.filtersOptions.map((filter, index) => {
              if (filterInputValue !== '') {
                if (filter instanceof TextFilter) {
                  return (
                    <li key={index}>
                      {!props.filtersSelected.find(f => f.id === filter.id && f.query === filter.query) && (
                        <button onClick={() => { updateFilterState({ id: (filter.id as any), query: filter.query, value: filterInputValue }); setIsOpenFilterDropdown(false); setFilterInputValue('') }}>{filter.name} {filterQueries.textQueries[filter.query].toLowerCase()} "{filterInputValue}"</button>
                      )}
                    </li>
                  )
                }
                return;
              }

              return (
                <div key={index}>
                  {!props.filtersSelected.find(f => f.id === filter.id && f.query === filter.query) && (
                    <li>
                      <button onClick={() => { selectFilterType(filter) }}>{filter.name}</button>
                    </li>
                  )}
                </div>
              );
            })}
          </ul>)}
      </div>
    </div>
  );
}