import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { events } from "./bean_event";
import { relations } from "drizzle-orm";

export const linkOrganizationEvents = sqliteTable('link_organization_events', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidEvent: text('uuid_event').notNull().references(() => events.uuid),
});

export const linkOrganizationEventsRelations = relations(linkOrganizationEvents, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkOrganizationEvents.uuidOrganization],
    references: [organizations.uuid],
  }),
  event: one(events, {
    fields: [linkOrganizationEvents.uuidEvent],
    references: [events.uuid],
  }),
}));