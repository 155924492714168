import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { devices } from "./bean_device";
import { users } from "./bean_user";
import { relations } from "drizzle-orm";

export enum DeviceUserStatus {
  LoggedIn = 'loggedIn',
  LoggedOut = 'loggedOut',
}

export const linkDeviceUserStatusses = sqliteTable('link_device_user_statusses', {
  ...schemaBase(),
  uuidDevice: text('uuid_device').notNull().references(() => devices.uuid),
  uuidUser: text('uuid_user').notNull().references(() => users.uuid),
  status: text('status', { enum: Object.values(DeviceUserStatus) as [DeviceUserStatus] }).notNull(),
});

export const linkDeviceUserStatussesRelations = relations(linkDeviceUserStatusses, ({ one }) => ({
  device: one(devices, {
    fields: [linkDeviceUserStatusses.uuidDevice],
    references: [devices.uuid],
  }),
  user: one(users, {
    fields: [linkDeviceUserStatusses.uuidUser],
    references: [users.uuid],
  }),
}));
