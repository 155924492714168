import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { beacons } from "./bean_beacon";
import { sites } from "./bean_site";
import { relations } from "drizzle-orm";

export const linkSiteBeacons = sqliteTable('link_site_beacons', {
  ...schemaBase(),
  uuidSite: text('uuid_site').notNull().references(() => sites.uuid),
  uuidBeacon: text('uuid_beacon').notNull().references(() => beacons.uuid),
});

export const linkSiteBeaconsRelations = relations(linkSiteBeacons, ({ one }) => ({
  site: one(sites, {
    fields: [linkSiteBeacons.uuidSite],
    references: [sites.uuid],
  }),
  beacon: one(beacons, {
    fields: [linkSiteBeacons.uuidBeacon],
    references: [beacons.uuid],
  }),
}));