import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { dashActivityLogs } from "./bean_dash_activity_log";
import { dashStateLogs } from "./bean_dash_state_log";

export const dashSessions = sqliteTable('dash_sessions', {
  ...schemaBase(),
  browserType: text('browser_type').notNull(),
  operatingSystem: text('operating_system').notNull(),
});

export const dashSessionsRelations = relations(dashSessions, ({many}) => ({
  activityLogs: many(dashActivityLogs),
  stateLogs: many(dashStateLogs),
}));