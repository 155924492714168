import { TTFunction } from "tt-coms";

type DeleteLinkType =
  | { link: 'site'; to: 'beacons' }
  | { link: 'beacon'; to: 'sites' }
  | { link: 'user'; to: 'roles' }
  | { link: 'role'; to: 'users' }

export function createDeleteLink() {
  const baseFunction = TTFunction<{ type: DeleteLinkType; uuidOrganization: string; uuidLink: string; uuidsTo: string[] }, void>();

  return async function (input: { type: DeleteLinkType; uuidOrganization: string; uuidLink: string; uuidsTo: string[] }): Promise<void> {
    return baseFunction(input);
  }
}