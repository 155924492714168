import content from 'vigil-map/dist/index.html?raw';
import { useEffect, useRef, useState } from 'react';
import { VIGIL_MAP_MSG_NAME, VigilMapMessage, VigilMapMessageId, VigilMapV2Props, VigilMapV2ThemeId } from 'vigil-map';
import { StatusLoading } from './status_loading';

export interface VigilMapController {
  sendVigilMessage: (msg: VigilMapMessage) => void
}

export interface MapWrapperProps {
  state: VigilMapV2Props
  setMapController: (controller: VigilMapController) => void
  children?: React.ReactNode
  loading?: boolean
  error?: string
}

export const VigilMap: React.FC<MapWrapperProps> = (props) => {
  const elementIframe = useRef(null as HTMLIFrameElement | null);
  const [stateController, setController] = useState(null as VigilMapController | null);
  const [stateFirstMessageToMap, setFirstMessageToMap] = useState(false);

  useEffect(() => {
    if (!stateController) { return }
    props.setMapController(stateController);
  }, [stateController])

  useEffect(() => {
    if (!stateController) { return }
    stateController.sendVigilMessage({
      id: VigilMapMessageId.SET_STATE,
      state: props.state,
    })
  }, [stateController, props.state])

  useEffect(() => {
    const messageReceived = (event: any) => {
      try {
        const json = JSON.parse(event.data);

        if (!json['id']) {
          throw new Error('No id field found on message')
        }

        if (!Object.keys(VigilMapMessageId).includes(json['id'])) {
          throw new Error('Id field does not match vigil message ids')
        }

        if (json['id'] == VigilMapMessageId.LOADED) {
          const controller: VigilMapController = {
            sendVigilMessage: (msg) => {
              elementIframe.current?.contentWindow?.postMessage(JSON.stringify(msg), "*");
            }
          }
          setController(controller);
          new Promise((resolve) => setTimeout(resolve, 250)).then(() => {
            setFirstMessageToMap(true);
          });
        }
      } catch (error) {
        console.warn("Vigil map received an unknown message. Error below..")
        console.warn(error)
      }
    };

    window.addEventListener(VIGIL_MAP_MSG_NAME, messageReceived);
    return () => {
      window.removeEventListener(VIGIL_MAP_MSG_NAME, messageReceived);
    };
  }, [elementIframe]);

  function getLoadingColor() {
    if (props.state.theme == VigilMapV2ThemeId.DARK_MATTER) {
      return "rgba(50, 50, 50, 1)"
    }
    return "rgba(255, 255, 255, 1)"
  }

  function shouldLoad() {
    return props.loading || !stateFirstMessageToMap
  }

  return (
    <div className='w-full h-full'>
      <div className='relative w-full h-full'>
        <iframe ref={elementIframe} srcDoc={content} height={'100%'} width={'100%'} frameBorder="0" />
        {
          shouldLoad() && (
            <div style={{ position: "absolute", left: "0px", top: "0px", width: "100%", height: "100%", backgroundColor: getLoadingColor() }}>
              <div className='w-full h-full flex flex-col justify-center'>
                <div className='mx-auto'>
                  <StatusLoading />
                </div>
              </div>
            </div>
          )
        }
        {
          !shouldLoad() && (
            <div className='absolute left-0 top-0 w-full h-full pointer-events-none'>
              {props.children}
            </div>
          )
        }
      </div>
    </div>
  )
}
