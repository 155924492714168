import { DurationState } from "../../components/input_duration";
import { TimeState } from "../../components/input_time";

export interface Occurance {
  MON: boolean;
  TUE: boolean;
  WED: boolean;
  THU: boolean;
  FRI: boolean;
  SAT: boolean;
  SUN: boolean;
}

function getTimeZoneOffset() {
  const now = new Date();
  return now.getTimezoneOffset() / 60;
}

export function getCronExpression(timeInstance: TimeState, occuranceInstance: Occurance) {
  const offset = getTimeZoneOffset();
  const tempHour = timeInstance.hour + offset;
  const days: number[] = [];
  if (occuranceInstance.MON) days.push(1);
  if (occuranceInstance.TUE) days.push(2);
  if (occuranceInstance.WED) days.push(3);
  if (occuranceInstance.THU) days.push(4);
  if (occuranceInstance.FRI) days.push(5);
  if (occuranceInstance.SAT) days.push(6);
  if (occuranceInstance.SUN) days.push(0);
  const hour = timeInstance.period == 'PM' ? tempHour + 12 : tempHour;
  return `${timeInstance.minute} ${hour} * * ${days.join(',')}`;
}

/**
 * Calculates the total duration in seconds based on the provided DurationState object.
 * If any of the duration properties (hours, minutes, seconds) is undefined, it defaults to 0.
 *
 * @param durationInstance - The DurationState object containing the duration properties.
 * @returns The total duration in seconds.
 */
export function getDuration(durationInstance: DurationState) {
  const hours = durationInstance.hours ?? 0;
  const minutes = durationInstance.minutes ?? 0;
  const seconds = durationInstance.seconds ?? 0;
  return hours * 3600 + minutes * 60 + seconds;
}

export function getStartTimeInStr(cronExpression: string) {
  const offset = getTimeZoneOffset();
  const [minuteStr, hourStr] = cronExpression.split(' ').slice(0, 2);
  const hour = parseInt(hourStr) - offset;
  const minute = parseInt(minuteStr);
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour > 12 ? hour - 12 : hour;
  const hourStrWithLeadingZero = hour12.toString().padStart(2, '0');
  const minuteStrWithLeadingZero = minute.toString().padStart(2, '0');
  return `${hourStrWithLeadingZero}:${minuteStrWithLeadingZero} ${period}`;
}

export function getOccuranceInStr(cronExpression: string) {
  const [, , , , daysStr] = cronExpression.split(' ');
  const days = daysStr.split(',').map(day => parseInt(day));
  const dayStr = days.map(day => {
    switch (day) {
      case 0: return 'SUN';
      case 1: return 'MON';
      case 2: return 'TUE';
      case 3: return 'WED';
      case 4: return 'THU';
      case 5: return 'FRI';
      case 6: return 'SAT';
    }
  });
  return dayStr.join(', ');
}

export function getDurationInStr(durationMS: number) {
  const milliseconds = durationMS % 1000;
  const seconds = Math.floor((durationMS / 1000) % 60);
  const minutes = Math.floor((durationMS / 1000 / 60) % 60);
  const hours = Math.floor((durationMS / 1000 / 60 / 60) % 24);
  const days = Math.floor(durationMS / 1000 / 60 / 60 / 24);

  const parts: string[] = [];

  if (days > 0) {
    parts.push(`${days} day${days > 1 ? 's' : ''}`);
  }
  if (hours > 0) {
    parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  }
  if (seconds > 0) {
    parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
  }
  if (milliseconds > 0) {
    parts.push(`${milliseconds} millisecond${milliseconds > 1 ? 's' : ''}`);
  }

  return parts.join(', ');
}

export function getTimeState(cronExpression: string) {
  const offset = getTimeZoneOffset();
  const [minuteStr, hourStr] = cronExpression.split(' ').slice(0, 2);
  const hour = parseInt(hourStr) - offset;
  const minute = parseInt(minuteStr);
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour > 12 ? hour - 12 : hour;
  return {
    minute,
    hour: hour12,
    period: period as 'AM' | 'PM',
  };
}

export function getOccurance(cronExpression: string) {
  const [, , , , daysStr] = cronExpression.split(' ');
  const days = daysStr.split(',').map(day => parseInt(day));
  return {
    MON: days.includes(1),
    TUE: days.includes(2),
    WED: days.includes(3),
    THU: days.includes(4),
    FRI: days.includes(5),
    SAT: days.includes(6),
    SUN: days.includes(0),
  };
}

/**
 * Calculates the duration state in hours, minutes, and seconds.
 * 
 * @param duration - The duration in seconds.
 * @returns An object containing the calculated hours, minutes, and seconds.
 */
export function getDurationState(duration: number) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = duration - hours * 3600 - minutes * 60;
  return {
    hours,
    minutes,
    seconds,
  };
}
