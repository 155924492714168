import { Outlet, RouteObject } from "react-router-dom";
import { FullPageError } from "../components/full_page_error";
import { Director } from "./director";
import { RouterBaseAuth } from "./auth/router_base_auth";
import { RouterBasePublic } from "./public/router_base_public";
import { ProviderUser } from "../providers/provider_user";
import { ProviderNotifications } from "../providers/provider_notification";
import { ProviderOrganization } from "../providers/provider_organization";
import { ProviderRoles } from "../providers/provider_roles";
import { ROUTES } from "./routes";

const Bootflow: React.FC = () => {
  return (
    <Director>
      <Outlet />
    </Director>
  )
}

function AuthenticatedApp() {
  return (
    <ProviderUser>
      <ProviderOrganization>
        <ProviderNotifications />
        <ProviderRoles>
          <Bootflow />
        </ProviderRoles>
      </ProviderOrganization>
    </ProviderUser>
  )
}

function PublicApp() {
  return (
    <Bootflow />
  )
}

export const RouterBase: RouteObject[] = [
  {
    path: ROUTES.ROUTE_PUBLIC,
    element: <PublicApp />,
    errorElement: <FullPageError />,
    children: [
      ...RouterBasePublic,
    ]
  },
  {
    path: "/",
    element: <AuthenticatedApp />,
    errorElement: <FullPageError />,
    children: [
      ...RouterBaseAuth,
    ]
  },
]