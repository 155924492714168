import { useContext, useEffect, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { Modal, ModalProps } from "../../../components/modal";
import { BeaconType, IBeaconInsert, ISiteSelect } from "vigil-datamodel";
import { StatusAlert } from "../../../components/status_alert";
import { StatusLoading } from "../../../components/status_loading";
import { TTuuid } from "tt-uuid";
import { getBeaconDefaultName } from "./helper_beacon";
import { VigilQRScan } from "../../../components/qr_scan";

interface ModalBeaconCreateProps extends ModalProps {
  uuidSite?: string;
  onSubmit?: () => Promise<void>;
}

export const ModalBeaconCreate: React.FC<ModalBeaconCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateSameUuid, setSameUuid] = useState<boolean>(false);

  const [stateSiteLoading, setSiteLoading] = useState(true);
  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  const [stateBeaconCreate, setBeaconCreate] = useState<IBeaconInsert>({
    name: '',
    latitude: 0,
    longitude: 0,
    altitude: 0,
    type: BeaconType.QR,
    radius: 5
  });

  const [stateSite, setSite] = useState<ISiteSelect>();

  const [stateLocation, setLocation] = useState<GeolocationPosition>();

  const [stateQrCodeOpen, setQrCodeOpen] = useState(true);

  /* Lifecycle */
  useEffect(() => { resetCalls(); }, [props.uuidSite]);

  useEffect(() => {
    if (!stateLocation) return;
    setBeaconCreate({
      ...stateBeaconCreate,
      latitude: stateLocation.coords.latitude,
      longitude: stateLocation.coords.longitude,
      altitude: stateLocation.coords.altitude ?? 0,
    });
  }, [stateLocation]);

  /* Functions */
  async function resetCalls() {
    if (!organization.data) return;

    let beaconDefaultName = '';
    if (props.uuidSite) {
      fetchSite();
      beaconDefaultName = await getBeaconDefaultName(vigil, organization.data.uuid, { uuidSite: props.uuidSite });
    } else {
      setSiteLoading(false);
      beaconDefaultName = await getBeaconDefaultName(vigil, organization.data.uuid, { startWith: 'XX' });
    }

    setBeaconCreate({
      name: beaconDefaultName,
      latitude: 0,
      longitude: 0,
      altitude: 0,
      type: BeaconType.QR,
      radius: 5
    })
    getLocation()
    setQrCodeOpen(true);
  }

  function getLocation() {
    if (!navigator.geolocation) {
      setError('Geolocation is not supported by your browser');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation(position);
        setError('');
      },
      (error) => {
        setError(error.message);
      }
    );
  };

  async function successScan(uuid: string) {
    if (!organization.data) return;
    setSameUuid(false);
    setBeaconCreate({ ...stateBeaconCreate, uuid });
    getLocation();
    setQrCodeOpen(false);

    const beacon = await vigil.functions.findOneOnOrganization({ type: 'beacons', uuidOrganization: organization.data.uuid, uuid });
    if (beacon != undefined) setSameUuid(true);
  }

  /* DB Functions */
  async function fetchSite() {
    try {
      if (!organization.data || !props.uuidSite) return;
      setError('');
      setSiteLoading(true);
      const site = await vigil.functions.findOneOnOrganization({ type: 'sites', uuidOrganization: organization.data.uuid, uuid: props.uuidSite });
      setSite(site);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSiteLoading(false);
    }
  }

  async function createBeacon() {
    if (!organization.data) return;
    try {
      setError('');
      setSubmitLoading(true);
      const beacon = await vigil.functions.createOneOnOrganization({ type: 'beacons', uuidOrganization: organization.data.uuid, data: stateBeaconCreate });
      props.uuidSite && await vigil.functions.createLink({ type: { link: 'site', to: 'beacons' }, uuidOrganization: organization.data.uuid, uuidLink: props.uuidSite, uuidsTo: [beacon.uuid] });
      props.onSubmit && await props.onSubmit();
      props.toggle();
      resetCalls();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  /* Validation */
  function validateBeaconUuid() {
    if (!stateBeaconCreate.uuid) return ['Beacon uuid is required'];
    if (TTuuid.isValidCuuid(stateBeaconCreate.uuid)) return [];
    return ['Beacon uuid is not valid'];
  }

  function validateBeaconName() {
    if (!stateBeaconCreate.name) return [];
    return validate(stateBeaconCreate.name, [VALIDATORS.length('Beacon name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateBeaconCreate.name) { required.push('Beacon name is required') }
    return [
      ...required,
      ...validateBeaconUuid(),
      ...validateBeaconName(),
    ];
  }

  if (stateSiteLoading) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <StatusLoading />
      </Modal>
    )
  };

  function BeaconWithSameUuid() {
    return <>
      <StatusAlert type='alert-error' message={'Beacon with this UUID already exist, do you want to overwrite it?'} />
      <div className="flex justify-end pt-2">
        <InputButton text='Overwrite' loading={stateSubmitLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await createBeacon()}></InputButton>
      </div>
    </>
  }

  function BeaconWithDifferentUuid() {
    return <>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={stateSubmitLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await createBeacon()}></InputButton>
      </div>
    </>
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Beacon</h3>
      {stateSite && <p className="text-sm pb-4">Create a new beacon for site <span className="font-bold">{stateSite.name}</span></p>}
      {stateLocation && <p className="text-sm pb-4"><span className="font-bold">Location:</span> {stateLocation.coords.latitude}, {stateLocation.coords.longitude}, {stateLocation.coords.altitude}</p>}
      {stateBeaconCreate.uuid && <p className="text-sm pb-4"><span className="font-bold">QR code:</span> {stateBeaconCreate.uuid}</p>}
      {validateBeaconUuid().length > 0 && <p className="text-sm pb-4 text-error">{validateBeaconUuid()[0]}</p>}
      {!stateQrCodeOpen && <InputButton text='Rescan QR code' loading={false} disabled={false} type='btn-primary' onClick={() => setQrCodeOpen(true)}></InputButton>}
      {stateQrCodeOpen && <VigilQRScan onScan={(data: any) => successScan(data.uuid)}></VigilQRScan>}
      <InputText labelText='Beacon name' value={stateBeaconCreate.name} onChange={(e) => { setError(''); setBeaconCreate({ ...stateBeaconCreate, name: e.target.value }); }} errors={validateBeaconName()} > </InputText>
      {stateSameUuid ? <BeaconWithSameUuid /> : <BeaconWithDifferentUuid />}
    </Modal>
  )
}
