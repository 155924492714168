import { sqliteTable, text, real } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { linkSiteBeacons } from "./bean_link_site_beacon";
import { linkOrganizationBeacons } from "./bean_link_organization_beacon";

export enum BeaconType {
  GEO = 'GEO',
  QR = 'QR',
  NFC = 'NFC',
  WIFI = 'WIFI',
  BLE = 'BLE',
}

export const beacons = sqliteTable('beacons', {
  ...schemaBase(),
  name: text('name').notNull(),
  radius: real('radius').notNull(),
  longitude: real('longitude').notNull(),
  latitude: real('latitude').notNull(),
  altitude: real('altitude').notNull(),
  type: text('type', { enum: Object.values(BeaconType) as [BeaconType] }).notNull(),
});

export const beaconsRelations = relations(beacons, ({ many }) => ({
  beaconsToOrganizations: many(linkOrganizationBeacons),
  beaconsToSites: many(linkSiteBeacons),
}));