import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { sites } from "./bean_site";
import { relations } from "drizzle-orm";

export const linkOrganizationSites = sqliteTable('link_organization_sites', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidSite: text('uuid_site').notNull().references(() => sites.uuid),
});

export const linkOrganizationSitesRelations = relations(linkOrganizationSites, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkOrganizationSites.uuidOrganization],
    references: [organizations.uuid],
  }),
  site: one(sites, {
    fields: [linkOrganizationSites.uuidSite],
    references: [sites.uuid],
  }),
}));