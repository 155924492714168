import { useContext, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { IReportSelect, ReportConfig, ReportConfigType } from "vigil-datamodel";
import { InputSelect, OptionSelect } from "../../../components/input_select";
import { reportConfigOptions } from "./helper_report";
import { ReportPatrol } from "./report_patrol";

interface ModalReportCreateProps extends ModalProps {
  onSubmit?: (report: IReportSelect) => Promise<void>;
}

export const ModalReportCreate: React.FC<ModalReportCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');

  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  const [stateName, setName] = useState<string>('');
  const [stateSelectedConfig, setSelectedConfig] = useState<OptionSelect>(reportConfigOptions[0]);

  const [stateConfig, setConfig] = useState<ReportConfig | undefined>();
  const [stateValidateFormChild, setValidateFormChild] = useState<string[]>([]);

  /* DB Functions */
  async function createReport() {
    try {
      if (!organization.data) throw new Error('Organization not found');
      setError('');
      setSubmitLoading(true);
      const report = await vigil.functions.createOneOnOrganization({
        type: 'reports',
        uuidOrganization: organization.data.uuid,
        data: {
          uuidOrganization: organization.data.uuid,
          name: stateName,
          config: stateConfig as ReportConfig
        }
      });
      props.onSubmit && await props.onSubmit(report);
      props.toggle();
      resetState();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  /* Functions */
  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function onChangeSelectedConfig(event: React.ChangeEvent<HTMLSelectElement>) {
    const config = reportConfigOptions.find((config) => config.value == parseInt(event.target.value));
    if (config) {
      setSelectedConfig(config);
    }
  }

  function resetState() {
    setName('');
    setConfig(undefined);
  }

  /* Validation */
  function validateName() {
    if (!stateName) return [];
    return validate(stateName, [VALIDATORS.length('Report name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateName) { required.push('Report name is required') }
    return [
      ...required,
      ...validateName(),
      ...stateValidateFormChild,
    ];
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-192">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Report</h3>
      <div className="flex flex-wrap">
        <InputText className="w-60 mb-2 mr-4" labelText='Report name' value={stateName} onChange={onChangeName} errors={validateName()} > </InputText>
        <InputSelect className="w-48 mb-2 mr-4" errors={[]} labelText="Report Config" value={stateSelectedConfig.value} options={reportConfigOptions} onChange={onChangeSelectedConfig}> </InputSelect>
      </div>
      {stateSelectedConfig.value == ReportConfigType.Patrol && <ReportPatrol config={stateConfig as any} setConfig={setConfig} setValidateForm={setValidateFormChild} />}
      {stateError && <StatusAlert message={stateError} type={"alert-error"} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={stateSubmitLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await createReport()}></InputButton>
      </div>
    </Modal>
  )
}