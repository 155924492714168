// import React, { useContext, useState } from 'react';
// import { InputButton } from '../../components/input_button';
// import { VigilChat } from 'vigil-datamodel';
// import { sleep } from 'tt-coms';
// import { StatusAlert } from '../../components/status_alert';
// import { ContextVigilClient } from '../../providers/provider_vigil_client';
// import { ContextUser } from '../../providers/provider_user';
// import { ContextOrganization } from '../../providers/provider_organization';

interface ScreenHomeAssistentProps { }

// interface ChatCardProps {
//   chat: VigilChat
// }

// const ChatCard: React.FC<ChatCardProps> = (props) => {
//   if (props.chat.role === 'assistant') {
//     return (
//       <div className="chat chat-start">
//         <div className="chat-image avatar">
//           <div className="w-10 rounded-full">
//             <img src="/icon.png" />
//           </div>
//         </div>
//         <div className="chat-bubble">{props.chat.content}</div>
//       </div>
//     )
//   }
//   if (props.chat.role === 'user') {
//     return (
//       <div className="chat chat-end">
//         <div className="chat-image avatar">
//           <div className="w-10 rounded-full">
//             <img src="/icon.png" />
//           </div>
//         </div>
//         <div className="chat-bubble">{props.chat.content}</div>
//       </div>
//     )
//   }
//   return null
// }

export const ScreenHomeAssistent: React.FC<ScreenHomeAssistentProps> = (props) => {
  //   const vigil = useContext(ContextVigilClient);
  //   /* State */
  //   const user = useContext(ContextUser);
  //   const organization = useContext(ContextOrganization);

  //   const [stateLoading, setLoading] = useState(false);
  //   const [stateError, setError] = useState("");
  //   const [stateMessage, setMessage] = useState("");
  //   const [stateMessages, setMessages] = useState([] as VigilChat[]);

  //   /* UI Updates */
  //   function onChangeMessage(event: React.ChangeEvent<HTMLTextAreaElement>) {
  //     setMessage(event.target.value);
  //   }
  //   function onInputMessage(event: any) {
  //     const target = event.target as HTMLElement;
  //     target.style.overflowY = 'hidden';
  //     target.style.height = 'auto';
  //     target.style.height = `${target.scrollHeight}px`;

  //     if (target.scrollHeight > 200) {
  //       target.style.overflowY = 'scroll';
  //     }
  //   }
  //   function onKeyDownMessage(event: any) {
  //     if (event.keyCode === 13 && !event.shiftKey) {
  //       event.preventDefault(); // Prevents the default Enter key behavior
  //       actionChat();
  //     }
  //   }

  //   /* Actions */
  //   async function actionChat() {
  //     try {
  //       setLoading(true);
  //       setError('');

  //       setMessage('');
  //       const result = await vigil.functions.chat({
  //         chats: [
  //           ...stateMessages,
  //           {
  //             role: 'user',
  //             name: 'user',
  //             content: stateMessage,
  //           }
  //         ],
  //         chatContext: {
  //           uuidOrganization: organization.data!.uuid,
  //           uuidUser: user.data!.uuid
  //         }
  //       })

  //       setMessages(result);
  //       await sleep(1000);
  //     }
  //     catch (error: any) {
  //       setError(error.message);
  //     }
  //     finally {
  //       setLoading(false);
  //     }
  //   }

  //   return (
  //     <div className='bg-gray-800 rounded'>
  //       <div className='flex justify-center'>
  //         <div className='w-2/4'>
  //           <h1 className='text-4xl my-5 text-center'> Assistent </h1>
  //           {
  //             stateMessages.length == 0 &&
  //             <div>
  //               Some info text
  //             </div>
  //           }
  //           {
  //             stateMessages.length > 0 &&
  //             stateMessages.map((chat) => {
  //               return (
  //                 <div>
  //                   <ChatCard chat={chat} />
  //                 </div>
  //               )
  //             })
  //           }


  //           <div className='m-6'>
  //             <div className="flex py-[10px] md:py-4 md:p-4 border border-black/10 bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-700 rounded-xl shadow-xs dark:shadow-xs">
  //               <textarea placeholder="Send a message" className="textarea p-1 m-0 w-full resize-none border-0 bg-transparent dark:bg-transparent " style={{ maxHeight: '200px', overflowY: 'hidden' }}
  //                 value={stateMessage}
  //                 onChange={onChangeMessage}
  //                 onInput={onInputMessage}
  //                 onKeyDown={onKeyDownMessage}>
  //               </textarea>
  //               <span className='ml-3'>
  //                 <InputButton text='Send' loading={stateLoading} disabled={false} type='btn-primary' onClick={() => actionChat()}></InputButton>
  //               </span>

  //             </div>

  //             <div className="my-2">
  //               {stateError && <StatusAlert message={stateError} type='alert-error' />}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );

  return <></>
};
