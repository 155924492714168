import { FC, useContext, useState } from 'react';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { useParams } from 'react-router-dom';
import { InputButton } from '../../components/input_button';
import { TTuuid } from 'tt-uuid';
import { InputSelect } from '../../components/input_select';
import { IconPencilSquareSolid } from '../../components/icons';
import { InputCronDuration } from '../../components/input_cron_duration';
import { InputCron } from '../../components/input_cron';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { useCaller } from '../../hooks/hook_caller';

const behaviourOptions = [
  { value: "DeviceBehaviourType.TimedSignIn", label: 'Timed Sign In' },
  { value: "DeviceBehaviourType.TimedSignOut", label: 'Timed Sign Out' }
];

interface ScreenHomeDeviceBehavioursProps { }

export const ScreenHomeDeviceBehaviours: FC<ScreenHomeDeviceBehavioursProps> = (props) => {
  // const vigil = useContext(ContextVigilClient);
  // const params = useParams();
  // const uuidDevice = params['uuid'] || '';

  // const [stateUpdateLoading, setUpdateLoading] = useState(false);
  // const [stateUpdateError, setUpdateError] = useState('');

  // const [stateBehaviours, setBehaviours] = useState<any[]>([]);

  // const [stateBehaviourValidationErrors, setBehaviourValidationErrors] = useState<{ behaviourUuid: string, error: string[] }[]>([]);

  // const stateLiveBehaviours = useCaller({
  //   callback: async () => {
  //     const result = await vigil.functions.deviceFindOne({ filter: { uuid: uuidDevice } });
  //     setBehaviours([]);
  //     return [];
  //   }
  // });

  // async function saveBehaviours() {
  //   try {
  //     setUpdateLoading(true);
  //     await vigil.functions.deviceUpdate({ uuid: uuidDevice, deviceInfo: { behaviours: stateBehaviours } });
  //   } catch (error: any) {
  //     setUpdateError(error.message);
  //   } finally {
  //     setUpdateLoading(false);
  //   }
  // }

  // /* State Functions */
  // function addDefaultBehaviour() {
  //   setBehaviours([...stateBehaviours, { uuid: TTuuid.getCuuid(), type: "", data: { cron: '0 3 * * 1,2,3,4,5,6,0', lifetimeMS: 1000 * 60 * 60 } }]);
  // }

  // function removeBehaviour(uuid: string) {
  //   setBehaviours(stateBehaviours.filter((behaviour) => behaviour.uuid !== uuid));
  //   setBehaviourValidationErrors(stateBehaviourValidationErrors.filter((item) => item.behaviourUuid !== uuid));
  // }

  // function onChangeBehaviour(uuid: string, event: React.ChangeEvent<HTMLSelectElement>) {
  //   const behaviourOption = behaviourOptions.find((behaviourOption) => behaviourOption.value == event.target.value);
  //   if (behaviourOption) {
  //     setBehaviours(stateBehaviours.map((behaviour) => behaviour.uuid === uuid ? { ...behaviour, type: behaviourOption.value as any } : behaviour));
  //   }
  // }

  // /* Validation */
  // function saveButtonDisabled() {
  //   if (stateBehaviourValidationErrors.length === 0) return true;
  //   return stateBehaviourValidationErrors.map((item) => item.error.length > 0).some(Boolean);
  // }

  // const BehaviourComponent = (behaviour: any) => {
  //   function onChangeBehaviourCron(cron: string) {
  //     const newBehaviourCron = stateBehaviours.map((item) => item.uuid === behaviour.uuid ? { ...item, data: { ...(item.data) as any, cron } } : item);
  //     if (behaviour.type === "DeviceBehaviourType.TimedSignOut") {
  //       delete (newBehaviourCron.find((item) => item.uuid === behaviour.uuid)?.data as any).lifetimeMS;
  //     }
  //     setBehaviours(newBehaviourCron);
  //   }

  //   function onChangeBehaviourLifetimeMS(lifetimeMS: number) {
  //     setBehaviours(stateBehaviours.map((item) => item.uuid === behaviour.uuid ? { ...item, data: { ...(item.data) as any, lifetimeMS } } : item));
  //   }

  //   function onChangeBehaviourValidationErrors(errors: string[]) {
  //     const filterBehaviourValidationErrors = stateBehaviourValidationErrors.filter((item) => item.behaviourUuid === behaviour.uuid);
  //     if (filterBehaviourValidationErrors.length === 0) {
  //       const newBehaviourValidationErrors = [...stateBehaviourValidationErrors, { behaviourUuid: behaviour.uuid, error: errors }];
  //       setBehaviourValidationErrors(newBehaviourValidationErrors);
  //       return
  //     }
  //     const newBehaviourValidationErrors = stateBehaviourValidationErrors.map((item) => item.behaviourUuid === behaviour.uuid ? { behaviourUuid: item.behaviourUuid, error: errors } : item);
  //     setBehaviourValidationErrors(newBehaviourValidationErrors);
  //   }

  //   return (
  //     <div key={behaviour.uuid} className="bg-base-200 p-1 mt-2 rounded-xl relative">
  //       <button className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1" onClick={() => removeBehaviour(behaviour.uuid)}>✕</button>
  //       <InputSelect className="w-48 mb-2 mr-4" errors={[]} labelText="Behaviour Option" value={behaviour.type} options={behaviourOptions} onChange={(event) => onChangeBehaviour(behaviour.uuid, event)}> </InputSelect>
  //       {/* {behaviour.type === DeviceBehaviourType.TimedSignIn &&
  //         <div>
  //           <div className="text-sm italic"> This behaviour prompts the user to sign in within the specific timeframe </div>
  //           <InputCronDuration
  //             cron={behaviour.data.cron}
  //             lifetimeMS={behaviour.data.lifetimeMS || 1000 * 60 * 60}
  //             validationErrors={[]}
  //             onChangeCron={onChangeBehaviourCron}
  //             onChangeLifetimeMS={onChangeBehaviourLifetimeMS}
  //             onValidationErrors={onChangeBehaviourValidationErrors}
  //           />
  //         </div>}
  //       {behaviour.type === DeviceBehaviourType.TimedSignOut &&
  //         <div>
  //           <div className="text-sm italic"> This behaviour signs the user out on the specific time </div>
  //           <InputCron
  //             cron={behaviour.data.cron}
  //             validationErrors={[]}
  //             onChangeCron={onChangeBehaviourCron}
  //             onValidationErrors={onChangeBehaviourValidationErrors}
  //           />
  //         </div>} */}
  //     </div>
  //   );
  // };

  // if (stateUpdateError) return <StatusAlert message={stateUpdateError} type='alert-error' />
  // if (stateLiveBehaviours.error) return <StatusAlert message={stateLiveBehaviours.error} type='alert-error' />
  // if (stateLiveBehaviours.loading) return <FullPageLoader />

  // return (
  //   <div className='h-full w-full flex flex-col'>
  //     <div className='relative  h-full'>
  //       <div className='absolute w-full h-full overflow-y-scroll'>
  //         {stateBehaviours.map((behaviour, index) => BehaviourComponent(behaviour))}
  //         <div className="flex mb-2 mt-2">
  //           <InputButton text='+ Add Behaviour' type='btn-link' onClick={addDefaultBehaviour}></InputButton>
  //           <InputButton text='Save' before={<IconPencilSquareSolid className='h-5 mr-1' />} type='btn-primary' onClick={saveBehaviours} loading={stateUpdateLoading}></InputButton>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
  return <></>
};
