import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '../../components/navbar/navbar';
import { Sidebar } from '../../components/navbar/sidebar';
import useBreakpoints from '../../hooks/hook_breakpoints';
import { ContextOrganization } from '../../providers/provider_organization';

interface ScreenHomeProps { }

export const ScreenHome: React.FC<ScreenHomeProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const breakpoints = useBreakpoints();

  const [stateMenuMobile, setMenuMobile] = useState(false);
  const [stateMenuMobileOpen, setMenuMobileOpen] = useState(false);
  const [stateMenuMinimized, setMenuMinimized] = useState(false);

  useEffect(() => {
    const isMobile = breakpoints['MD'] == false;
    isMobile && setMenuMinimized(false);
    setMenuMobile(isMobile);
  }, [breakpoints])

  /* Actions */
  function actionToggleMenu() {
    stateMenuMobile && setMenuMobileOpen(!stateMenuMobileOpen);
    !stateMenuMobile && setMenuMinimized(!stateMenuMinimized);
  }

  return (
    <div className="flex flex-col h-screen">
      <Navbar onClickMenu={actionToggleMenu} />
      <div className="flex flex-1 overflow-hidden">
        {organization.data && (
          <Sidebar
            mobile={stateMenuMobile}
            mobileOpen={stateMenuMobileOpen}
            mobileOnMenuClick={actionToggleMenu}
            minimized={stateMenuMinimized}
          />
        )}
        <div className="h-full flex-1 overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default ScreenHome
