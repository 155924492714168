import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IEventInstanceSelect } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';

interface ScreenHomeEventInstanceOverviewProps { }

export const ScreenHomeEventInstanceOverview: React.FC<ScreenHomeEventInstanceOverviewProps> = (props) => {
  /* State */
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const eventInstanceId = params['uuid'] || '';

  const [stateEventInstance, setEventInstance] = useState(null as IEventInstanceSelect | null);
  const [stateLoading, setLoading] = useState(true);
  const [stateError, setError] = useState("");

  async function fetchEventInstances() {
    try {
      if (!organization.data) return;
      setLoading(true);
      const response = await vigil.functions.findOneOnOrganization({
        type: 'eventInstances',
        uuidOrganization: organization.data.uuid,
        uuid: eventInstanceId
      });
      if (!response) return;
      setEventInstance(response);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  // Update immediately
  useEffect(() => {
    fetchEventInstances();
  }, [organization.data]);

  const code = JSON.stringify(stateEventInstance, null, 4);

  return (
    <div className='w-full h-full'>
      <div className='relative w-full h-full'>
        <div className='absolute w-full h-full'>
          <div className=' h-full overflow-scroll'>
            <div className="mockup-code whitespace-pre px-4">
              {code}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
