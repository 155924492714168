import React, { useState } from 'react';
import { IDirectorySite } from 'vigil-datamodel';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { QueryFilter } from '../../components/table/table_filters/table_filter_types';
import { Paging, SelectedAction, Sorting, UnselectedAction } from '../../components/table/table_types';

interface SitesTableProps {
  sites: IDirectorySite[];
  loading: boolean;
  error: string;
  onSiteClick: (site: IDirectorySite) => void;
  onFiltersChange: (filters: QueryFilter<IDirectorySite>[]) => void;
  onSortingChange: (sorting: Sorting) => void;
  tablePagingSelected: Paging;
  onPagingChange: (paging: Paging) => void;
  selectedActions?: SelectedAction<IDirectorySite>[];
  unSelectedActions?: UnselectedAction[];
  hoverActions?: SelectedAction<IDirectorySite>[];
  selectable?: boolean;
}

export const SitesTable: React.FC<SitesTableProps> = ({
  sites,
  loading,
  error,
  onSiteClick,
  onFiltersChange,
  onSortingChange,
  tablePagingSelected,
  onPagingChange,
  selectedActions,
  unSelectedActions,
  hoverActions,
  selectable = false,
}) => {
  const [stateTableFiltersSelected, setTableFiltersSelected] = useState<QueryFilter<IDirectorySite>[]>([]);
  const [stateTableSortingSelected, setTableSortingSelected] = useState<Sorting>({ id: 'uuid', direction: 'descending' });
  const [stateTablePagingSelected, setTablePagingSelected] = useState<Paging>({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 });

  const handleFiltersChange = (filters: QueryFilter<IDirectorySite>[]) => {
    setTableFiltersSelected(filters);
    onFiltersChange(filters);
  };

  const handleSortingChange = (sorting: Sorting) => {
    setTableSortingSelected(sorting);
    onSortingChange(sorting);
  };

  const handlePagingChange = (paging: Paging) => {
    setTablePagingSelected(paging);
    onPagingChange(paging);
  };

  return (
    <Table<IDirectorySite>
      className='h-full flex flex-col drop-shadow'
      name='Sites'
      data={sites}
      selectable={selectable}
      loading={loading}
      error={error}
      columns={[
        {
          id: 'name',
          header: 'Name',
          sortable: true,
          value(item) {
            return (
              <div className='hover:underline cursor-pointer text-blue-500' onClick={() => onSiteClick(item)}>
                {item.name}
              </div>
            );
          },
        },
        {
          id: 'beaconCount',
          header: 'Beacons Linked',
          sortable: true,
          value(item) {
            return <div>{item.beaconCount}</div>;
          },
        },
        {
          id: 'uuid',
          header: 'Date Created',
          sortable: true,
          value(item) {
            const decodedUuid = TTuuid.decodeCuuid(item.uuid);
            return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
          },
        },
        {
          id: 'changeStamp',
          header: 'Last Updated',
          sortable: true,
          value(item) {
            const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
            return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
          },
        },
      ]}
      filtersOptions={[
        new TextFilter<IDirectorySite>('name', 'Name', 'contains'),
        new DateFilter('uuid', 'Date Created', 'between'),
        new DateFilter('changeStamp', 'Last Updated', 'between'),
      ]}
      filtersSelected={stateTableFiltersSelected}
      onFiltersChange={handleFiltersChange}
      sortingOptions={null}
      sortingSelected={stateTableSortingSelected}
      onSortingChange={handleSortingChange}
      pagingOptions={null}
      pagingSelected={tablePagingSelected}
      onPagingChange={handlePagingChange}
      unSelectedActions={unSelectedActions}
      selectedActions={selectedActions}
      hoverActions={hoverActions}
    />
  );
};