import jwt_decode from "jwt-decode";
import { IDeviceSelect, IUserSelect } from "./beans_type";

export type TokenType = 'user' | 'device';

export interface IVigilTokenUser {
  type: 'user'
  user: IUserSelect
  expires: number
}

export interface IVigilTokenDevice {
  type: 'device'
  device: IDeviceSelect
  expires: number
}

export type TokenTypeMap = {
  'user': IVigilTokenUser
  'device': IVigilTokenDevice
};

export function getTokenType(token: string): TokenType {
  const tokenDecoded = jwt_decode(token) as Record<string, any>;
  return tokenDecoded['type'] as TokenType;
}

export function tokenDecoder<T extends TokenType>(
  token: string,
  type: T
): TokenTypeMap[T] {
  try {
    const tokenDecoded = jwt_decode(token) as Record<string, any>;

    if (tokenDecoded['type'] === type) {
      return tokenDecoded as TokenTypeMap[T];
    }

    throw new Error(`Invalid token type. Expected ${type}, but got ${tokenDecoded['type']}.`);
  } catch (error) {
    throw new Error(`Token decoding error: ${error}`);
  }
}

export function tokenValidator(token: string, type: TokenType): boolean {
  const tokenDecoded = tokenDecoder(token, type);
  return tokenDecoded ? new Date().valueOf() < tokenDecoded['expires'] : false;
}
