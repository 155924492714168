import { TTFunction } from "tt-coms";
import { IActionSelect, IBeaconSelect, IDeviceSelect, IEventSelect, ILinkOrganizationRoleUserSelect, INotificationLogSelect, INotificationSelect, IOrganizationRoleSelect, IReportSelect, ISiteSelect, IUserSelect } from "./beans_type";
import { QueryFilter } from "./query_filter";

export type FindManyOrganization = 'users' | 'sites' | 'beacons' | 'devices' | 'events' | 'organizationRoles' | 'userRoles' | 'actions' | 'reports' | 'notifications';

type InferResultFindManyOrganization = {
  'users': IUserSelect[],
  'sites': ISiteSelect[],
  'beacons': IBeaconSelect[],
  'devices': IDeviceSelect[],
  'events': IEventSelect[],
  'organizationRoles': IOrganizationRoleSelect[],
  'userRoles': IOrganizationRoleSelect[],
  'actions': IActionSelect[],
  'reports': IReportSelect[],
  'notifications': INotificationSelect[],
}

type InferFilterFindManyOrganization = {
  'users': QueryFilter<IUserSelect>[],
  'sites': QueryFilter<ISiteSelect>[],
  'beacons': QueryFilter<IBeaconSelect>[],
  'devices': QueryFilter<IDeviceSelect>[],
  'events': QueryFilter<IEventSelect>[],
  'organizationRoles': QueryFilter<IOrganizationRoleSelect>[],
  'userRoles': QueryFilter<ILinkOrganizationRoleUserSelect>[],
  'actions': QueryFilter<IActionSelect>[],
  'reports': QueryFilter<IReportSelect>[],
  'notifications': QueryFilter<INotificationSelect>[],
}

export function createFindManyOrganization() {
  const baseFunction = TTFunction<{ type: FindManyOrganization; uuidOrganization: string, filters: InferFilterFindManyOrganization[FindManyOrganization] }, InferResultFindManyOrganization[FindManyOrganization]>();

  return async function <T extends FindManyOrganization>(input: { type: T, uuidOrganization: string, filters: InferFilterFindManyOrganization[T] }): Promise<InferResultFindManyOrganization[T]> {
    return baseFunction(input) as Promise<InferResultFindManyOrganization[T]>;
  }
}

// ========================================

type FindManySite = 'beacons';

type InferResultFindManySite = {
  'beacons': IBeaconSelect[],
}

export function createFindManySite() {
  const baseFunction = TTFunction<{ uuidOrganization: string, type: FindManySite; uuidSite: string }, InferResultFindManySite[FindManySite]>();

  return async function <T extends FindManySite>(input: { uuidOrganization: string, type: T, uuidSite: string }): Promise<InferResultFindManySite[T]> {
    return baseFunction(input) as Promise<InferResultFindManySite[T]>;
  }
}

// ========================================

type FindManyUnlinkedFromSite = 'beacons';

type InferResultFindManyUnlinkedFromSite = {
  'beacons': IBeaconSelect[],
}

export function createFindManyUnlinkedFromSite() {
  const baseFunction = TTFunction<{ uuidOrganization: string, type: FindManyUnlinkedFromSite; uuidSite: string }, InferResultFindManyUnlinkedFromSite[FindManyUnlinkedFromSite]>();

  return async function <T extends FindManyUnlinkedFromSite>(input: { uuidOrganization: string, type: T, uuidSite: string }): Promise<InferResultFindManyUnlinkedFromSite[T]> {
    return baseFunction(input) as Promise<InferResultFindManyUnlinkedFromSite[T]>;
  }
}

// ========================================

type FindManyBeacon = 'sites';

type InferResultFindManyBeacon = {
  'sites': ISiteSelect[],
}

export function createFindManyBeacon() {
  const baseFunction = TTFunction<{ uuidOrganization: string, type: FindManyBeacon; uuidBeacon: string }, InferResultFindManyBeacon[FindManyBeacon]>();

  return async function <T extends FindManyBeacon>(input: { uuidOrganization: string, type: T, uuidBeacon: string }): Promise<InferResultFindManyBeacon[T]> {
    return baseFunction(input) as Promise<InferResultFindManyBeacon[T]>;
  }
}

// ========================================

type FindManyUnlinkedFromBeacon = 'sites';

type InferResultFindManyUnlinkedFromBeacon = {
  'sites': ISiteSelect[],
}

export function createFindManyUnlinkedFromBeacon() {
  const baseFunction = TTFunction<{ uuidOrganization: string, type: FindManyUnlinkedFromBeacon; uuidBeacon: string }, InferResultFindManyUnlinkedFromBeacon[FindManyUnlinkedFromBeacon]>();

  return async function <T extends FindManyUnlinkedFromBeacon>(input: { uuidOrganization: string, type: T, uuidBeacon: string }): Promise<InferResultFindManyUnlinkedFromBeacon[T]> {
    return baseFunction(input) as Promise<InferResultFindManyUnlinkedFromBeacon[T]>;
  }
}

// ========================================

type FindManyUser = 'organizationRoles';

type InferResultFindManyUser = {
  'organizationRoles': IOrganizationRoleSelect[],
}

export function createFindManyUser() {
  const baseFunction = TTFunction<{ uuidOrganization: string, type: FindManyUser; uuidUser: string }, InferResultFindManyUser[FindManyUser]>();

  return async function <T extends FindManyUser>(input: { uuidOrganization: string, type: T, uuidUser: string }): Promise<InferResultFindManyUser[T]> {
    return baseFunction(input) as Promise<InferResultFindManyUser[T]>;
  }
}

// ========================================

type FindManyOrganizationRole = 'users';

type InferResultFindManyOrganizationRole = {
  'users': IUserSelect[],
}

export function createFindManyOrganizationRole() {
  const baseFunction = TTFunction<{ uuidOrganization: string, type: FindManyOrganizationRole; uuidRole: string }, InferResultFindManyOrganizationRole[FindManyOrganizationRole]>();

  return async function <T extends FindManyOrganizationRole>(input: { uuidOrganization: string, type: T, uuidRole: string }): Promise<InferResultFindManyOrganizationRole[T]> {
    return baseFunction(input) as Promise<InferResultFindManyOrganizationRole[T]>;
  }
}

// ========================================

type FindManyNotification = 'notificationLogs';

type InferResultFindManyNotification = {
  'notificationLogs': INotificationLogSelect[],
}

export function createFindManyNotification() {
  const baseFunction = TTFunction<{ uuidOrganization: string, type: FindManyNotification; uuidNotification: string }, InferResultFindManyNotification[FindManyNotification]>();

  return async function <T extends FindManyNotification>(input: { uuidOrganization: string, type: T, uuidNotification: string }): Promise<InferResultFindManyNotification[T]> {
    return baseFunction(input) as Promise<InferResultFindManyNotification[T]>;
  }
}
