import { TTFunction } from "tt-coms";
import { IActionSelect, IBeaconSelect, IDeviceSelect, IEventInstanceSelect, IEventSelect, INotificationLogSelect, INotificationSelect, IOrganizationRoleSelect, IReportSelect, ISiteSelect, IUserSelect } from "./beans_type";

export type FindOneOnOrganization = 'users' | 'sites' | 'beacons' | 'devices' | 'events' | 'eventInstances' | 'organizationRoles' | 'actions' | 'reports' | 'notifications' | 'notificationLogs';

type InferResultFindOneOnOrganization = {
  'users': IUserSelect,
  'sites': ISiteSelect,
  'beacons': IBeaconSelect,
  'devices': IDeviceSelect,
  'events': IEventSelect,
  'eventInstances': IEventInstanceSelect,
  'actions': IActionSelect,
  'reports': IReportSelect,
  'notifications': INotificationSelect,
  'notificationLogs': INotificationLogSelect,
  'organizationRoles': IOrganizationRoleSelect
}

export function createFindOneOnOrganization() {
  const baseFunction = TTFunction<{ type: FindOneOnOrganization; uuidOrganization: string; uuid: string }, InferResultFindOneOnOrganization[FindOneOnOrganization] | undefined>();

  return async function <T extends FindOneOnOrganization>(input: { type: T, uuidOrganization: string, uuid: string }): Promise<InferResultFindOneOnOrganization[T] | undefined> {
    return baseFunction(input) as Promise<InferResultFindOneOnOrganization[T] | undefined>;
  }
}
