import React, { useRef, useState } from 'react';
import { TTuuid } from 'tt-uuid';
import { InputText } from '../../components/input_text';
import { InputButton } from '../../components/input_button';
import VigilIconRaw from '../../assets/vigil_icon_raw.svg';
import qrcode from 'qrcode';

interface ScreenAdminBeaconsProps { }

export const ScreenAdminBeacons: React.FC<ScreenAdminBeaconsProps> = (props) => {

  const [stateBeaconCount, setBeaconCount] = useState<number>(0);
  const [stateBeaconsData, setBeaconsData] = useState<string[]>([]);

  const beaconRef = useRef<HTMLDivElement>();

  function getBeaconSVGDataURL(uuidBeacon: string) {
    let dataUrl = '';
    qrcode.toString(`vigl.me/${uuidBeacon}/qr`, {
      errorCorrectionLevel: 'Q',
      type: 'svg'
    }, (error, data) => {
      dataUrl = `data:image/svg+xml;utf8,${encodeURIComponent(data)}`
    });
    return dataUrl;
  }

  function onChangeBeaconCount(val: React.ChangeEvent<HTMLInputElement>) {
    const beacons: string[] = [];
    for (let i = 0; i < (val.target.value as any); i++) {
      beacons.push(getBeaconSVGDataURL(TTuuid.getCuuid()));
    }
    setBeaconsData(beacons);
    setBeaconCount(val.target.value as any);
  }

  function printBeacons() {
    const content = beaconRef.current;
    if (!content) return;
    const windowPrint = window.open('', '_blank');
    if (!windowPrint) return;
    windowPrint.document.write(content.outerHTML);
    windowPrint.document.close();
    windowPrint.focus();
    windowPrint.print();
  };

  return (
    <div className='p-4 h-full w-full'>
      <div className='flex'>
        <InputText errors={[]} labelText='Beacon Count' inputType='numberInt' value={stateBeaconCount} onChange={onChangeBeaconCount} />
        <div className='w-full'></div>
        <InputButton disabled={stateBeaconCount == 0} text='Print Beacons' type='btn-primary' onClick={printBeacons} />
      </div>
      <div ref={beaconRef as React.MutableRefObject<HTMLDivElement>} style={{ display: 'flex', flexWrap: 'wrap', fontFamily: 'Arial, Helvetica, sans-serif' }}>
        {stateBeaconsData.map((data, index) => {
          return (
            <div key={index} style={{ position: 'relative', marginRight: '3mm', marginBottom: '3mm', width: '42mm', height: '47mm' }}>
              <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderWidth: '0.01mm', borderStyle: 'solid', borderColor: 'red', borderRadius: '2mm' }}></div>
              <img src={data} />
              <div style={{ position: 'absolute', width: '42mm', bottom: '1.5mm' }}>
              <div style={{ display: 'flex', justifyContent: 'center', verticalAlign: 'middle', alignItems: 'center' }}>
                <img style={{ height: '5mm', marginRight: '1mm' }} src={VigilIconRaw} />
                <div>vigl.me</div>
              </div>
              </div>
            </div>
          );
        })}
      </div>
    </div >
  );
};
