import { lazy } from "react";
import { ROUTES } from "../routes";
import { FullPageError } from "../../components/full_page_error";
import { FullPageSuspend } from "../../components/full_page_suspend";
import { RouteObject } from "react-router-dom";

const ScreenPublicReport = lazy(() => import('../../screens/public/report/public_report'));
const ScreenPublicReportDevices = lazy(() => import('../../screens/public/report/public_report_devices'));
const ScreenPublicReportDevice = lazy(() => import('../../screens/public/report/public_report_device'));
const ScreenPublicReportDeviceLogsActivity = lazy(() => import('../../screens/public/report/public_report_device_logs_activity'));
const ScreenPublicReportDeviceLogsState = lazy(() => import('../../screens/public/report/public_report_device_logs_state'));
const ScreenPublicReportEvents = lazy(() => import('../../screens/public/report/public_report_events'));
const ScreenPublicReportEvent = lazy(() => import('../../screens/public/report/public_report_event'));
const ScreenPublicReportOverview = lazy(() => import('../../screens/public/report/public_report_overview'));
const ScreenPublicReportSites = lazy(() => import('../../screens/public/report/public_report_sites'));
const ScreenPublicReportSite = lazy(() => import('../../screens/public/report/public_report_site'));

export const RouterPublic: RouteObject[] = [{
  path: ROUTES.ROUTE_PUBLIC_REPORT,
  errorElement: <FullPageError />,
  element: <FullPageSuspend><ScreenPublicReport /></FullPageSuspend>,
  children: [
    {
      path: ROUTES.ROUTE_PUBLIC_REPORT_OVERVIEW,
      element: <ScreenPublicReportOverview />
    },
    {
      path: ROUTES.ROUTE_PUBLIC_REPORT_EVENTS,
      element: <ScreenPublicReportEvents />
    },
    {
      path: ROUTES.ROUTE_PUBLIC_REPORT_EVENT,
      element: <ScreenPublicReportEvent />
    },
    {
      path: ROUTES.ROUTE_PUBLIC_REPORT_SITES,
      element: <ScreenPublicReportSites />
    },
    {
      path: ROUTES.ROUTE_PUBLIC_REPORT_SITE,
      element: <ScreenPublicReportSite />
    },
    {
      path: ROUTES.ROUTE_PUBLIC_REPORT_DEVICES,
      element: <ScreenPublicReportDevices />
    },
    {
      path: ROUTES.ROUTE_PUBLIC_REPORT_DEVICE,
      element: <ScreenPublicReportDevice />,
      children: [
        {
          path: ROUTES.ROUTE_PUBLIC_REPORT_DEVICE_LOGS_ACTIVITY,
          element: <ScreenPublicReportDeviceLogsActivity />
        },
        {
          path: ROUTES.ROUTE_PUBLIC_REPORT_DEVICE_LOGS_STATE,
          element: <ScreenPublicReportDeviceLogsState />
        },
      ]
    },
  ]
}];