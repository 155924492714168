import { TTFunction } from "tt-coms";
import { IActionInsert, IActionSelect, IBeaconInsert, IBeaconSelect, IDeviceInsert, IDeviceSelect, IEventInsert, IEventSelect, IOrganizationRoleInsert, IOrganizationRoleSelect, IOrganizationRoleUserInviteInsert, IOrganizationRoleUserInviteSelect, IReportInsert, IReportSelect, ISiteInsert, ISiteSelect, IUserInsert, IUserSelect } from "./beans_type";

export type CreateOneOnOrganization = 'users' | 'sites' | 'beacons' | 'devices' | 'events' | 'organizationRoles' | 'organizationRoleUserInvites' | 'actions' | 'reports';

type InferInputCreateOneOnOrganization = {
  'users': IUserInsert,
  'sites': ISiteInsert,
  'beacons': IBeaconInsert,
  'devices': IDeviceInsert,
  'events': IEventInsert,
  'actions': IActionInsert,
  'reports': IReportInsert,
  'organizationRoles': IOrganizationRoleInsert,
  'organizationRoleUserInvites': IOrganizationRoleUserInviteInsert
}

type InferResultCreateOneOnOrganization = {
  'users': IUserSelect,
  'sites': ISiteSelect,
  'beacons': IBeaconSelect,
  'devices': IDeviceSelect,
  'events': IEventSelect,
  'actions': IActionSelect,
  'reports': IReportSelect,
  'organizationRoles': IOrganizationRoleSelect,
  'organizationRoleUserInvites': IOrganizationRoleUserInviteSelect
}

export function createCreateOneOnOrganization() {
  const baseFunction = TTFunction<{ type: CreateOneOnOrganization; uuidOrganization: string; data: InferInputCreateOneOnOrganization[CreateOneOnOrganization] },  InferResultCreateOneOnOrganization[CreateOneOnOrganization]>();

  return async function <T extends CreateOneOnOrganization>(input: { type: T, uuidOrganization: string, data: InferInputCreateOneOnOrganization[T] }): Promise<InferResultCreateOneOnOrganization[T]> {
    return baseFunction(input) as Promise<InferResultCreateOneOnOrganization[T]>;
  }
}
