import { ReactNode, createContext } from "react";
import { FullPageLoader } from "../components/full_page_loader";
import { FullPageError } from "../components/full_page_error";
import { IGenericContext } from "./type_generic_context";
import { useCaller } from "../hooks/hook_caller";
import { Preferences } from "../preference_keys";

export const ContextTheme = createContext(null as any as IGenericContext<string>);

interface ProviderThemeProps {
  children: ReactNode
}
export const ProviderTheme: React.FC<ProviderThemeProps> = (props: ProviderThemeProps) => {
  /* State */
  const theme = useCaller({
    callback: async () => {
      const theme = localStorage.getItem(Preferences.SELECTED_THEME);
      if (!theme) return 'light';
      return theme;
    },
  });

  if (theme.loading) return <FullPageLoader location={"theme"} />
  if (theme.error) return <FullPageError error={theme.error} />

  return (
    <div className="h-full" data-theme={theme.result}>
      <ContextTheme.Provider value={{
        data: theme.result,
        loading: theme.loading,
        error: theme.error,
        refresh: theme.call,
      }}>
        {props.children}
      </ContextTheme.Provider>
    </div>
  )
}
