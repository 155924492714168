import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { ICreator } from "./type_creator";
import { relations } from "drizzle-orm";
import { notificationLogs } from "./bean_notification_log";

export enum NotificationType {
  Panic = 'panic',
  Generic = 'generic',
}

export interface INotificationPanic {
  userUuid: string,
  userFirstName: string,
  userLastName: string,
  deviceUuid?: string,
  deviceSerial?: string,
  siteUuid?: string,
  siteName?: string,
  lat: number,
  lng: number,
  alt: number,
  acc: number,
}

export interface INotificationGeneric {
  title: string,
  description: string,
  tags: string[],
  data: Record<string, any>,
}

export const notifications = sqliteTable('notifications', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  creator: customJsonColumn<ICreator>('creator').notNull(),
  type: text('type', { enum: Object.values(NotificationType) as [NotificationType] }).notNull(),
  data: customJsonColumn<INotificationPanic | INotificationGeneric>('data').notNull(),
});

export const notificationsRelations = relations(notifications, ({ one, many }) => ({
  organization: one(organizations, {
    fields: [notifications.uuidOrganization],
    references: [organizations.uuid],
  }),
  notificationLogs: many(notificationLogs)
}));